import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./360Tour.css";

const Tour360 = ({ section, colors }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentTourURL, setCurrentTourURL] = useState(null);

  useEffect(() => {
    document.title = `${section.titleSubsection}`;
  }, [section]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
            },
        },
        {
            breakpoint: 600,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
            },
        },
        {
            breakpoint: 480,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
            },
        },
    ]
  };

  const show360 = (url) => {
    setCurrentTourURL(url);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setCurrentTourURL(null);
  };

  return (
    <section
      className="pb__grid360_layout"
      style={{ display: "block" }}
      data-initiate="true"
    >
      <div className="pb__grid360_content">
        <p>
          3D <span>MODEL SUITES</span>
        </p>
        <div className="pb__grid360 slick-initialized slick-slider">
          <Slider {...settings}>
            {section?.tours?.map((tour, index) => (
              <div
                className="grid360_item slick-slide slick-current slick-active"
                key={index}
              >
                <figure>
                  <img src={tour.thumbnail_type} alt={tour.type} />
                </figure>
                <div className="title">
                  <span className="type_name">{tour.type}</span>
                  <span
                    className="show"
                    onClick={() => show360(tour?.["360_tour"])}
                  >
                    View model
                    <i className="show360"></i>
                  </span>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/* Lightbox */}
      {lightboxOpen && currentTourURL && (
        <div className="lightbox-overlay fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
          <div className="lightbox-content p-4 rounded-lg relative w-11/12 max-w-4xl">
            <button
              className="absolute top-4 right-4 text-black text-xl"
              onClick={closeLightbox}
            >
              &times;
            </button>
            <iframe
              src={currentTourURL}
              title="360 Tour"
              className="w-full h-[500px] rounded-lg"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </section>
  );
};

export default Tour360;
