/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import "../_styles/FloorplatesView.css";

const FloorplatesView = ({
  floorplates,
  floorplans,
  units,
  sketchStyle,
  setSelectedUnitRender,
  setSelectedFloorplan,
  setUnitFloor,
  setPointUnit
}) => {
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedNameUnit, setSelectedNameUnit] = useState(null);
  /**
   * Selects a unit based on the provided point and image.
   * 
   * @param {Object} point - The point object containing floorplate units and other details.
   * @param {Array} point.floorplate_unit - Array of floorplate units associated with the point.
   * @param {Object} point.unit - The unit object associated with the point.
   * @param {Object} point.floorplan - The floorplan object associated with the point.
   * @param {string} image - The image associated with the floorplate.
   * 
   * @returns {void}
   */
  
  const selectUnit = (point, image) => {
    if (point.floorplate_unit.length > 1) {
      const unit = point.floorplate_unit.map((a) => {
        return units.find((unit) => unit.unit_id === parseInt(a.unit_id));
      });

      const keyArr = Math.floor(Math.random() * unit.length);
      setSelectedFloorplan(
        floorplans.find((floorplan) => floorplan.fp_id === unit[keyArr].fp_id)
      );
      setSelectedUnitRender(unit[keyArr].unit_number);
      setSelectedNameUnit(unit[keyArr].unit_name);
      setSelectedUnit(unit[keyArr]);
      setUnitFloor(unit[keyArr].unit_floor);
      setPointUnit({point, floorplate_image: image});
      point.unit = unit[keyArr];
    } else {
      setSelectedFloorplan(point.floorplan);
      setSelectedUnitRender(point.unit?.unit_number);
      setSelectedUnit(point.unit);
      setSelectedNameUnit(point.unit?.unit_name);
      setPointUnit({point, floorplate_image: image});
      setUnitFloor(point.unit?.unit_floor);
    }
  };

  useEffect(() => {
    const handleClick = async (event) => {
      const closeButton = event.target.closest("#close-floorplan");
      if (closeButton) {
        setSelectedUnit(null);
        setSelectedUnitRender(null);
        setSelectedFloorplan(null);
        setUnitFloor(null);
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [selectedUnit, setSelectedFloorplan, setSelectedUnitRender, setUnitFloor, setPointUnit]);

  useEffect(() => {
    floorplates.map((floorplate, index) => {
      floorplate.floorplate_points?.point?.map((point, i) => {
        const unit = units.find(
          (unit) => unit.unit_id === parseInt(point.floorplate_unit?.unit_id)
        );
        if (unit) {
          point.unit = unit;
          point.floorplan = floorplans.find(
            (floorplan) => floorplan.fp_id === unit.fp_id
          );
        }
        return point;
      });
    });
  }, [floorplates, floorplans, units]);

  return (
    <div className="floorplates-gallery pt-10 text-center">
      {floorplates.map((floorplate) => (
        <div key={floorplate.id || floorplate.floorplate_name}>
          <div className="floorplate-item">
            <img src={floorplate.floorplate_image} alt={floorplate.floorplate_name} />

            {floorplate.floorplate_points?.point?.map((point) => {
              // Lógica de estilos extraída para mayor claridad
              const isSelectedUnit = selectedUnit?.unit_id === point.unit?.unit_id;
              const isSameFloorplan = point.floorplan?.fp_id === selectedUnit?.fp_id;
              const isAvailable = point.unit?.unit_status === "available";
              
              const background = selectedUnit
                ? isSelectedUnit
                  ? sketchStyle.unit_color
                  : isSameFloorplan
                    ? sketchStyle.available_color
                    : "transparent"
                : isAvailable
                  ? sketchStyle.available_color
                  : sketchStyle.sold_color;

              const border = isSameFloorplan || !selectedUnit ? "none" : "1px solid #000";
              const color = isSameFloorplan || !selectedUnit ? "#fff" : "#000";

              return (
                <div
                  key={point.point_number}
                  className="point"
                  onClick={() => selectUnit(point, floorplate.floorplate_image_low)}
                  style={{
                    top: `${point.point_position_y}%`,
                    left: `${point.point_position_x}%`,
                    background,
                    border,
                    color,
                  }}
                >
                  {point.point_number}
                </div>
              );
            })}
          </div>

          <h2>{floorplate.floorplate_name}</h2>
        </div>
      ))}
    </div>
  );
};

export default FloorplatesView;
