/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import config from "./config";
import Project from "./components/Project";
import { IframeProvider } from "./contexts/IframeContext";
import { getProjects, saveOrUpdateProject } from "./db";
import { convertToCSS } from "./plugins/FormatStyle.js";
import slugify from "react-slugify";
// Función para obtener datos del proyecto desde la API
const fetchProjectDataFromAPI = async (projectId) => {
  const response = await axios.get(
    `${config.API_URL}/project/name/${projectId}`,
    {
      headers: {
        apikey: config.API_KEY,
      },
    }
  );
  return response.data;
};

// Hook para obtener datos del proyecto
const useProjectData = (projectId) => {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAndSaveProjectData = useCallback(async () => {
    try {

      const storedProjects = await getProjects();
      const currentProject = storedProjects.find(
        (project) => project.id === projectId && project.fonts.length > 0
      );

      if (currentProject) {
        setProject(currentProject);
        addStyles(currentProject.styles);
      } else {

        const projectData = await fetchProjectDataFromAPI(projectId);

        await saveOrUpdateProject({
          id: projectData.id_project,
          name: projectData.project_name,
          slug: slugify(projectData.project_name),
          data: projectData.project_data,
          sketch_style: projectData.sketch_style,
          map: projectData.map,
          mapStyle: projectData?.mapStyle || "",
          fonts: projectData.fonts,
          styles: projectData.styles,
          content: projectData.content,
        });

        setProject(projectData);
        addStyles(projectData.styles);
      }
    } catch (err) {
      console.error("Error while fetching and saving project data:", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    if (projectId) {
      fetchAndSaveProjectData();
    }
  }, [projectId, fetchAndSaveProjectData]);

  return { project, loading, error };
};

const addStyles = (styles) => {
  if (styles === null) return;
  const styleElement = document.createElement("style");
  for (const key in styles) {
    if (Object.prototype.hasOwnProperty.call(styles, key)) {
      const s = styles[key];
      styleElement.textContent += convertToCSS(s);
    }
  }
  document.head.appendChild(styleElement);
  // console.log(document.head)
};

const ProjectWrapper = () => {
  const { projectId, "*": section } = useParams();
  const { project, loading, error } = useProjectData(projectId);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && project) {
      const sections = project?.content?.section || [];
      const [mainSection, subSection] = section?.split("/") || [];

      const foundSection = sections.find(
        (s) => slugify(s.title) === mainSection
      );
      if (!foundSection) {
        let firstValidPath = `/project/${projectId}/${slugify(
          sections[0].title
        )}`;
        if (sections[0].subsections?.length > 0) {
          firstValidPath = `/project/${projectId}/${slugify(
            sections[0].title
          )}/${slugify(sections[0].subsection[0].titleSubsection)}`;
        }
        navigate(firstValidPath, { replace: true });
        return;
      }

      if (subSection) {
        const foundSubSection = foundSection.subsection?.find(
          (sub) => slugify(sub.titleSubsection) === subSection
        );

        if (!foundSubSection) {
          if (foundSection.subsections?.length > 0) {
            navigate(
              `/project/${projectId}/${mainSection}/${slugify(
                foundSection.subsection[0].titleSubsection
              )}`,
              { replace: true }
            );
          } else {
            navigate(`/project/${projectId}/${mainSection}`, { replace: true });
          }
        }
      } else {
        if (foundSection.subsections?.length > 0) {
          navigate(
            `/project/${projectId}/${mainSection}/${slugify(
              foundSection.subsection[0].titleSubsection
            )}`,
            { replace: true }
          );
        }
      }
    }
  }, [loading, project, section, navigate, projectId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data: {error.message}</p>;

  return project?.project_data?.project_type === "LOWRISE" ? (
    <IframeProvider>
      <Project project={project} />
    </IframeProvider>
  ) : (
    <Project project={project} />
  );
};

const App = () => {
  return (

      <Router>
          <Routes>
            <Route path="/project/:projectId/*" element={<ProjectWrapper />} />
          </Routes>
      </Router>

  );
};

export default App;
