/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  InfoWindow,
  useMap,
} from "@vis.gl/react-google-maps";
import DetailsPoint from "./_parts/PointDetails.jsx";
import { MobileView } from "react-device-detect";
import { Bars4Icon, Squares2X2Icon } from "@heroicons/react/24/outline";
import config from "../../../config.js";
import "./_styles/MapComponent.css";

const mapContainerStyle = {
  width: "100%",
  height: "100vh",
};

const defaultZoom = 13;
const MY_MAPTYPE_ID = "custom_style";

const MapComponent = ({ mapCategories, colors, project, mapStyle }) => {
  const [categories, setCategories] = useState(mapCategories);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [showCategoriesMobile, setShowCategoriesMobile] = useState(false);
  const mapInstanceRef = useRef(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const center = project
    ? { lat: project.reflat, lng: project.reflng }
    : { lat: 0, lng: 0 };

  const styledMapOptions = {
    name: "Greyscale Map",
  };

  // Encuentra la categoría a la que pertenece un punto
  const findCategoryForPoint = useCallback(
    (point) => {
      return categories.find((category) =>
        category.point.some((p) => p.name === point.name)
      );
    },
    [categories]
  );

  // Calcula los límites para los marcadores visibles actualmente
  const calculateBounds = useCallback(
    (filteredCategories = null) => {
      if (!window.google || !window.google.maps) return null;

      const bounds = new window.google.maps.LatLngBounds();
      let hasPoints = false;

      if (project) {
        bounds.extend(
          new window.google.maps.LatLng(project.reflat, project.reflng)
        );
        hasPoints = true;
      }

      const categoriesToInclude = filteredCategories
        ? mapCategories.filter((cat) => cat.name === filteredCategories.name)
        : mapCategories;

      categoriesToInclude.forEach((category) => {
        category.point.forEach((point) => {
          bounds.extend(
            new window.google.maps.LatLng(point.reflat, point.reflng)
          );
          hasPoints = true;
        });
      });

      return hasPoints ? bounds : null;
    },
    [mapCategories, project]
  );

  // Ajusta el mapa a los límites calculados
  const fitMapToMarkers = useCallback(
    (filteredCategories = null) => {
      if (!mapInstanceRef.current) return;

      const bounds = calculateBounds(filteredCategories);

      if (bounds) {
        mapInstanceRef.current.fitBounds(bounds);
        const zoom = mapInstanceRef.current.getZoom();
        if (zoom > 14) {
          mapInstanceRef.current.setZoom(14);
        }
      }
    },
    [calculateBounds]
  );

  // Hook para acceder a la instancia del mapa
  const CustomMapControl = () => {
    const map = useMap();

    useEffect(() => {
      if (!map || !window.google) return;

      mapInstanceRef.current = map;
      setIsMapLoaded(true);

      // Aplicar estilos del mapa si existen
      if (mapStyle) {
        try {
          const stylesMapAsJSON = JSON.parse(mapStyle.styles_map);
          const customMapType = new window.google.maps.StyledMapType(
            stylesMapAsJSON,
            styledMapOptions
          );
          map.mapTypes.set(MY_MAPTYPE_ID, customMapType);
          map.setMapTypeId(MY_MAPTYPE_ID);
        } catch (error) {
          console.error("Error al aplicar estilos al mapa:", error);
        }
      }
    }, [map, mapStyle]); // Eliminamos fitMapToMarkers de las dependencias

    return null;
  };

  // Efecto para ajustar el mapa cuando está cargado
  useEffect(() => {
    if (isMapLoaded) {
      fitMapToMarkers(selectedCategory || null);
    }
  }, [isMapLoaded, selectedCategory, fitMapToMarkers]);

  // Renderiza iconos personalizados para los marcadores
  const renderCustomIcon = (point, isActive = false, category = null) => {
    let iconUrl;
    if (point.name === project?.name) {
      iconUrl = isActive ? project.markerIconActive : project.markerIcon;
    } else if (category) {
      iconUrl = isActive ? category.markerIconActive : category.markerIcon;
    } else {
      iconUrl = isActive
        ? colors?.activeMarkerIcon ||
          "https://maps.google.com/mapfiles/ms/icons/red-dot.png"
        : colors?.defaultMarkerIcon ||
          "https://maps.google.com/mapfiles/ms/icons/blue-dot.png";
    }

    const isProjectMarker = point.name === project?.name;

    return (
      <div
        style={{
          width: 0,
          height: 0,
          position: "relative",
        }}
      >
        <img
          src={iconUrl}
          alt={point.name}
          style={{
            position: "absolute",
            width:
              isProjectMarker && project?.width
                ? project?.width - 30 || "60px"
                : "40px",
            height:
              isProjectMarker && project?.height
                ? project?.height - 30 || "60px"
                : "40px",
            left: isProjectMarker ? "-30px" : "-20px",
            top: isProjectMarker ? "-60px" : "-40px",
            transform: isActive ? "scale(1.1)" : "scale(1)",
            transition: "transform 0.2s ease",
            zIndex: isProjectMarker ? 1000 : 1,
            filter: isProjectMarker
              ? "drop-shadow(0 0 8px rgba(0,0,0,0.3))"
              : "none",
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    setCategories(mapCategories);
  }, [mapCategories]);

  // Maneja el clic en una categoría
  const handleCategoryClick = useCallback(
    (category) => {
      if (selectedCategory === category) {
        // Deseleccionar categoría
        setSelectedCategory(null);
        setExpandedCategory(null);
        setSelectedPoint(null);
        fitMapToMarkers(); // Ajustar a todos los marcadores
      } else {
        // Seleccionar nueva categoría
        setSelectedCategory(category);
        setExpandedCategory(category);
        setSelectedPoint(null);
        fitMapToMarkers(category); // Ajustar solo a marcadores de esta categoría
      }
    },
    [selectedCategory, fitMapToMarkers]
  );

  // Maneja el clic en un marcador
  const handleMarkerClick = useCallback(
    (point) => {
      setSelectedPoint(point);
      setShowCategoriesMobile(false);

      // Encontrar y expandir la categoría del marcador
      const pointCategory = findCategoryForPoint(point);
      if (pointCategory) {
        setSelectedCategory(pointCategory);
        setExpandedCategory(pointCategory);
      }

      if (mapInstanceRef.current) {
        // Centrar y hacer zoom en el marcador seleccionado
        mapInstanceRef.current.panTo({ lat: point.reflat, lng: point.reflng });
        mapInstanceRef.current.setZoom(16);
      }
    },
    [findCategoryForPoint]
  );

  // Maneja el cierre del InfoWindow
  const handleCloseInfoWindow = useCallback(() => {
    setSelectedPoint(null);
    // Volver a la vista de los marcadores relevantes
    if (selectedCategory) {
      fitMapToMarkers(selectedCategory);
    } else {
      fitMapToMarkers();
    }
  }, [selectedCategory, fitMapToMarkers]);

  // Ajustar el mapa cuando cambian las categorías
  useEffect(() => {
    if (!selectedCategory && mapInstanceRef.current) {
      fitMapToMarkers();
    }
  }, [selectedCategory, fitMapToMarkers]);

  return (
    <div className="flex h-screen relative">
      {/* Panel lateral de categorías */}
      <div
        className={`w-1/4 categories-container ${
          MobileView && (showCategoriesMobile ? "visible" : "hide")
        }`}
        style={{
          background: colors?.mapInfoCont?.["background-color"] || "#000",
        }}
      >
        <ul className="categories-list">
          {categories.map((category) => (
            <li
              key={category.name}
              className="mb-2"
              style={{
                borderBottom: `1px solid ${
                  colors?.location__list?.color || "#ccc"
                }`,
              }}
            >
              <button
                className={`w-full text-left hover:text-gray-300 nameCategory ${
                  selectedCategory === category ? "active" : ""
                }`}
                onClick={() => handleCategoryClick(category)}
              >
                <img
                  src={category.menuIcon}
                  alt={category.name}
                  className="inline-block mr-2"
                />
                {category.name.replaceAll("_", " ")}
              </button>
              {expandedCategory === category && (
                <ul className="subcategories ml-4 mt-2 location__list">
                  {category.point.map((point) => (
                    <li
                      key={`${point.name}-${point.reflat}-${point.reflng}`}
                      className="mb-2"
                    >
                      <button
                        className={`w-full text-left text-sm location__list__element ${
                          selectedPoint === point ? "active" : ""
                        }`}
                        onClick={() => handleMarkerClick(point)}
                      >
                        {point.name}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Mapa de Google */}
      <div className="w-3/4 map-container">
        <APIProvider apiKey={config.GOOGLE_MAPS_API_KEY}>
          <Map
            style={mapContainerStyle}
            defaultCenter={center}
            defaultZoom={defaultZoom}
            mapId={config.MAP_ID || "DEMO_MAP_ID"}
            disableDefaultUI={true}
            zoomControl={true}
            scaleControl={true}
          >
            <CustomMapControl />

            {/* Marcadores de la categoría seleccionada o todos si no hay selección */}
            {selectedCategory
              ? selectedCategory.point.map((point) => (
                  <AdvancedMarker
                    key={`${point.name}-${point.reflat}-${point.reflng}`}
                    position={{ lat: point.reflat, lng: point.reflng }}
                    onClick={() => handleMarkerClick(point)}
                  >
                    {renderCustomIcon(
                      point,
                      selectedPoint?.name === point.name,
                      selectedCategory
                    )}
                  </AdvancedMarker>
                ))
              : categories.flatMap((category) =>
                  category.point.map((point) => (
                    <AdvancedMarker
                      key={`${point.name}-${point.reflat}-${point.reflng}`}
                      position={{ lat: point.reflat, lng: point.reflng }}
                      onClick={() => handleMarkerClick(point)}
                    >
                      {renderCustomIcon(point, false, category)}
                    </AdvancedMarker>
                  ))
                )}

            {/* Marcador del proyecto */}
            {project && (
              <AdvancedMarker
                position={{ lat: project.reflat, lng: project.reflng }}
                onClick={() => handleMarkerClick(project)}
              >
                {renderCustomIcon(
                  project,
                  selectedPoint?.name === project.name
                )}
              </AdvancedMarker>
            )}

            {/* InfoWindow con posición ajustada */}
            {selectedPoint && (
              <InfoWindow
                position={{
                  lat: selectedPoint.reflat + 0.0002, // Ajuste para posición arriba
                  lng: selectedPoint.reflng,
                }}
                onCloseClick={handleCloseInfoWindow}
                options={{
                  pixelOffset: new window.google.maps.Size(0, -40), // Ajuste vertical
                }}
              >
                <DetailsPoint point={selectedPoint} colors={colors} />
              </InfoWindow>
            )}
          </Map>
        </APIProvider>

        {/* Botones de vista móvil */}
        <div className="responsive-buttons">
          <button
            onClick={() => setShowCategoriesMobile(false)}
            className={`btn btn-primary ${
              !showCategoriesMobile ? "active" : ""
            }`}
          >
            <Squares2X2Icon className="size-6" />
          </button>
          <button
            onClick={() => setShowCategoriesMobile(true)}
            className={`btn btn-primary ${
              showCategoriesMobile ? "active" : ""
            }`}
          >
            <Bars4Icon className="size-6" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MapComponent;
