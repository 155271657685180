import React from "react";
import "./Columns.css";
const Columns = ({ section, sectionIndex, subsectionIndex, colors }) => {

    return (
        <section id="columns" style={{ display: "block" }}>
        <div id="columnsContainer">
            {section?.columns?.column?.map((column, index) => (
            <div
                key={index}
                className={`columns_column columns_column-${index} txtBgColor_${sectionIndex}_${subsectionIndex}`}
                style={{ backgroundColor: colors[`txtBgColor_${sectionIndex}_${subsectionIndex}`]?.color }}
            >
                <img
                className="columns_logo columns_block columnLogo-medium"
                src={column.logo?.urlMedium}
                alt={column.alt}
                />
                <div className="columns_block columnBlockContainer">
                <div className={`columns_block columnSubtitle textColor_${sectionIndex}_${subsectionIndex}`}>
                   {column.column_description.split('\r\n\r\n').map((text, i) => (
                       <p key={i}>{text}</p>
                   ))}
                </div>
                </div>
            </div>
            ))}
        </div>
        </section>
    );
};

export default Columns;