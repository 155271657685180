/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "../_styles/FullScreen.css";
import DetailFullFloorplan from "./DetailFullFloorplan";
const FullScreen = ({
  floorplan,
  sketchStyle,
  setIsFullScreen,
  isFullScreen,
  pointForUnit,
  displayPrices,
  views,
  setView,
  externalForm,
  openModal,
  isActiveComparation,
  btnReqInfo,
  isComparation,
  activeComparation,
  setIsComparation,
  getFloorplate,
  floorsFilter,
  masterSketchRef,
  setSelectedUnitRender,
  setPointUnit,
  setUnitOri,
  othersUnits,
  setUnitFloor,
  floorUnit
}) => {
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsFullScreen(false);
      setIsClosing(false);
    }, 300);
  };

  useEffect(() => {
  }, [floorplan, pointForUnit]);

  return (
    <div
      id="fpViewContainer"
      className={`${isFullScreen && !isActiveComparation ? "active" : ""} ${
        isClosing ? "closing" : ""
      } fullScreenContainer`}
    >
      <button
        id="closeView"
        className="closeView"
        onClick={handleClose}
      ></button>
      <div id="fpViewImage" className={`fpView`}>
        <div className="floorplate previewFloorplate">
          <div className="floorplateInner">
            <img
              className="floorplateImg"
              src={pointForUnit?.floorplate_image}
            />
            <div className="floorplateImgPoints">
              <div
                className="floorplatePoint pointUnit pointActive"
                style={{
                  top: `${pointForUnit?.point?.point_position_y}%`,
                  left: `${pointForUnit?.point?.point_position_x}%`,
                  border: `none`,
                  backgroundColor: sketchStyle?.unit_color,
                }}
              >
                <span className="floorplate_num">
                  {pointForUnit?.point?.point_number}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          id="fpViewImageContainer"
          style={{ overflow: "hidden", opacity: 1 }}
          className="h-screen"
        >
          <div
            id="fpViewImageInner"
            style={{ backgroundImage: `url(${floorplan?.fp_image_med?.[0]}` }}
          ></div>
        </div>
      </div>
      <div id="fpViewContent" className="active">
        <div id="fpViewContentContainer" style={{ opacity: 1 }}>
          <div className="floorplanPreviewTop">
            <div className="favourite favouritePreview favourite-10188"></div>
            <div className="floorplanPreviewTitle floorplanTitle">
              <div className="floorplanTitleInner titleFont">
                {floorplan.fp_name}
              </div>
            </div>
          </div>
          <div className="floorplanSideInfo">
            <div className="floorplanUnit floorplanUnitPreview">
              <span className="floorplanPriceFeaturePreview smallFeatureTitle">
                Unit
              </span>
              {pointForUnit?.point?.unit?.unit_name}
            </div>

            {}
            <div className="floorplanDynamic">
              {floorplan.fp_price !== "0" && displayPrices !== "0" && (
                <div className="floorplanPrice floorplanPricePreview">
                  <span className="floorplanPriceFeaturePreview smallFeatureTitle">
                    From
                  </span>
                  {parseInt(floorplan.fp_price).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}{" "}
                  / mth
                </div>
              )}
            </div>
          </div>
          <DetailFullFloorplan
            isComparation={isComparation}
            views={views}
            isActiveComparation={isActiveComparation}
            setView={setView}
            floorUnit={floorUnit}
            floorplan={floorplan}
            externalForm={externalForm}
            openModal={openModal}
            othersUnits={othersUnits}
            btnReqInfo={btnReqInfo}
            activeComparation={activeComparation}
            setIsComparation={setIsComparation}
            getFloorplate={getFloorplate}
            floorsFilter={floorsFilter}
            masterSketchRef={masterSketchRef}
            setSelectedUnitRender={setSelectedUnitRender}
            setPointUnit={setPointUnit}
            setUnitOri={setUnitOri}
            isFullScreen={true}
            handleClose={handleClose}
            setUnitFloor={setUnitFloor}
          />
        </div>
      </div>
    </div>
  );
};

export default FullScreen;
