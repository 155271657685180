/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable array-callback-return */
import React, {useState } from "react";
import "./BrokerAssets.css";
import md5 from "md5";

const BrokerAssets = ({ section, colors }) => {
  const [password, setPassword] = useState("");
  const [authenticate, setAuthenticate] = useState(false);
  const [error, setError] = useState(false);

  const handleAuthenticate = () => {
    if(md5(password) === section.passwordPage){
      setAuthenticate(!authenticate);
      setError(false);
    } else {
      setError(true)
    };
  };
  return (
    <div id="containerAssets">
      <div
        className="assetsBg"
        style={{
          backgroundImage: `url(${
            section.bgImageDsk ? section.bgImageDsk : "#fff"
          }`,
        }}
      ></div>
      <div id="contentAsset"></div>{" "}
      {section?.isPassword === "1" && !authenticate ? (
        <div className="authenticateAssets">
          <div className="assetsPassword">
            <div className="assetForm">
              <label>Please insert your password:</label><br />
              <input
                type="password"
                id="assetPass"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              /> <br />
              <button onClick={handleAuthenticate}>Login</button>
              {error && <p className="error mt-4">Password is incorrect</p>}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`outerListAssets ${section.assets?.position.toLowerCase()}`}
        >
          <div className="innerListAssets">
            <ul className="containerListAssets">
              {section?.assets &&
                section.assets.asset &&
                section.assets.asset.map((a, i) => (
                  <li className="itemAssets" key={i}>
                    <a
                      className="innerItemAsset"
                      href={a.assetUrl}
                      target="_blank"
                    >
                      <span className="subtitleFont innerItemAssetText">
                        {a.assetName}
                      </span>
                      <span className="iconInnerItemAssets"></span>
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default BrokerAssets;
