/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import config from "../../../config";
import { saveOrUpdateProject, getProjects } from "../../../db";
import Mastersketch from "../../../plugins/Mastersketch";
import SelectedFloorplanSlide from "./_parts/SelectedFloorplanSlide";
import Loader from "../../Templates/_Parts/Loader";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import "./_styles/FloorplanIndex.css";

import FilterBar from "./_parts/FilterBar";
import LegendBar from "./_parts/LegendBar";
import CompareFloorplanSlide from "./_parts/CompareFloorplanSlide";
import FloorplatesView from "./_parts/FloorplatesView";
import { isMobile } from "react-device-detect";
import GridFloorplans from "./_parts/GridFloorplans";
import slugify from "react-slugify";
import FullScreen from "./_parts/FullScreen";
import { isMobileDevice } from "../../../plugins/MobileDetect";
import ViewButtons from "./_parts/ViewButtons";

const FloorPlans = () => {
  const { projectId } = useParams();
  const [units, setUnits] = useState([]);
  const [clickedUnit, setClickedUnit] = useState(null);
  const [floorplans, setFloorplans] = useState([]);
  const [modelUrl, setModelUrl] = useState("");
  const [sketchStyle, setSketchStyle] = useState({});
  const [nodeGroupName, setNodeGroupName] = useState("");
  const [selectedFloorplan, setSelectedFloorplan] = useState(null);
  const [compareFloorplan, setCompareFloorplan] = useState(null);
  const [unitActiveID, setUnitActiveID] = useState(null);
  const [sketchSetup, setSketchSetup] = useState({});
  const [pointUnit, setPointUnit] = useState({});
  const [unitFloor, setUnitFloor] = useState(null);
  const [floorplates, setFloorplates] = useState([]);
  const [panoramaViews, setPanoramaViews] = useState([]);
  const [selectedUnitRender, setSelectedUnitRender] = useState(null);
  const [isPricing, setIsPricing] = useState(false);
  const [hideSold, setHideSold] = useState(false);
  const [has360, setHas360] = useState(false);
  const [externalForm, setExternalForm] = useState(null);
  const [typeColors, setTypeColors] = useState([]);
  const iframeRef = useRef(null);
  const [currentProject, setCurrentProject] = useState(null);
  const masterSketchRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [favorites, setFavs] = useState([]);
  const [worksheets, setWorksheets] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [stylesProject, setStylesProject] = useState([]);
  const [favoritesUnits, setFavoritesUnits] = useState(false);
  const [showFavs, setShowFavs] = useState(false);
  const [isRowFloorplan, setIsRowFloorplan] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [hasViewedFavorites, setHasViewedFavorites] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isMasterSketchReady, setIsMasterSketchReady] = useState(false);
  const [isLeftExpanded, setIsLeftExpanded] = useState(false);
  const [isRightExpanded, setIsRightExpanded] = useState(false);
  const [isComparation, setIsComparation] = useState(false);
  const [unitCompareFloorplan, setUnitCompareFloorplan] = useState(null);
  const [compareUnitRender, setCompareUnitRender] = useState(null);
  const [unitPointCompare, setUnitPointCompare] = useState(null);
  const [isFloorplatesView, setIsFloorplatesView] = useState(false);
  const [isRental, setIsRental] = useState(false);
  const [floorsFilter, setFloorsFilter] = useState([]);
  const [helperActive, setHelperActive] = useState(false);
  const useImperativeHandle = useRef(null);
  const filterBarRef = useRef();
  const isTablet = navigator.maxTouchPoints > 0;
  const isLandscape = window.matchMedia("(orientation: landscape)").matches;
  /**
   * Resets the boxes and updates the display of model availability on the master sketch.
   *
   * @async
   * @function resetBoxes
   * @returns {Promise<void>} A promise that resolves when the boxes are reset and the display is updated.
   */
  const resetBoxes = useCallback(async () => {
    const availableUnits = units
      .filter(
        (unit) =>
          unit.unit_status === "available" &&
          (!floorsFilter || floorsFilter.includes(unit.unit_floor))
      )
      .map((u) => u.unit_model_name);
    const soldUnits =
      currentProject?.data?.hide_sold === "1"
        ? []
        : units
            .filter(
              (unit) =>
                unit.unit_status !== "available" &&
                (!floorsFilter || floorsFilter.includes(unit.unit_floor))
            )
            .map((u) => u.unit_model_name);

    if (!showFavs) {
      await updateBuildBoxes(availableUnits, soldUnits, sketchStyle);
      setClickedUnit(null);
      setSelectedFloorplan(null);
      setSelectedUnitRender(null);
      setUnitFloor(null);
    }
  }, [
    sketchStyle,
    units,
    showFavs,
    favorites,
    isMasterSketchReady,
    floorsFilter,
  ]);

  /**
   * Initializes the Mastersketch plugin with the given data.
   *
   * @async
   * @function initializeMastersketch
   * @param {Object} data - The data to initialize the Mastersketch plugin with.
   * @returns {Promise<void>} A promise that resolves when the Mastersketch plugin is initialized.
   * @throws {Error} If there is an error initializing the Mastersketch plugin.
   *
   */

  const initializeMastersketch = useCallback(
    async (data) => {
      setLoading(true);
      try {
        const viewer = document.getElementById("api-frame");
        const modelURL =
          !isMobile && !isTablet
            ? data.info.modelURL
            : data.info.modelURLMobile;
        masterSketchRef.current = new Mastersketch(
          viewer,
          modelURL,
          data.sketch,
          data.units,
          data.info?.nodeGroupName,
          data.data?.hide_sold,
          data.sketch_style,
          data.id_project
        );
        setIsMasterSketchReady(true);
        await masterSketchRef.current.onClick(
          async (f_id, unit_render, unit_floor, unit_id) => {
            if (!floorplates || floorplates.length === 0) {
              console.warn("No floorplates data available");
              return;
            }

            setClickedUnit(f_id);
            setSelectedUnitRender(unit_render);
            setUnitFloor(unit_floor);
            setUnitActiveID(unit_id);

            const point = await getFloorplate(unit_id);
            setPointUnit(point);
          }
        );
      } catch (error) {
        console.error("Error initializing Mastersketch:", error);
      } finally {
        setLoading(false);
      }
    },
    [floorplates, isMobile, isTablet]
  );

  const addParams = useCallback(
    (data) => {
      const uniqueFloorplans = data.floorplans.filter(
        (fp, index, self) =>
          index === self.findIndex((t) => t.fp_id === fp.fp_id)
      );

      const availableFloorplans = uniqueFloorplans.filter(
        (fp) =>
          fp.fp_status[0] === "available" &&
          data.units.some(
            (unit) => unit.fp_id === fp.fp_id && unit.unit_status === "available"
          )
      );

      setUnits(data.units);
      setFloorplates(data.floorplates);
      setIsPricing(data.info?.display_prices || "0");
      setModelUrl(data.info?.modelURL);
      setSketchStyle(data.sketch_style);
      setNodeGroupName(data.info?.nodeGroupName);
      setFloorplans(availableFloorplans);
      setPanoramaViews(data.content.views?.view);
      setStylesProject(data.style_project);
      setHideSold(data.data?.hide_sold);
      setTypeColors(data.data?.types_colors);
      setExternalForm(data.data?.external_form_contact);
      setHas360(data.info?.has360 || "0");
      setIsRental(parseInt(data.data?.saleOrRent));
      setSketchSetup(data.sketch);
      setCurrentProject(data);
    },
    [hideSold]
  );

  /**
   * Fetches the project units data from the API and saves it to the database.
   * @async
   * @function fetchProjectUnitsData
   * @returns {Promise<void>} A promise that resolves when the project units data is fetched.
   * @throws {Error} If there is an error fetching the project units data.
   *
   */
  const fetchProjectUnitsData = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${config.API_URL}/project/name/${projectId}/building`,
        {
          headers: {
            apikey: config.API_KEY,
          },
        }
      );

      await saveOrUpdateProject({
        id: data.id_project,
        name: data.project_name,
        slug: slugify(data.project_name),
        units: data.units,
        floorplates: data.floorplates,
        info: data.info,
        content: data.content,
        styles: data.style_project,
        sketch_style: data.sketch_style,
        floorplans: data.floorplans,
        sketch: data.sketch,
      });

      addParams(data);

      if(data.info.modelURL !== "") await initializeMastersketch(data);
    } catch (error) {
      console.error("Error fetching project units data:", error);
    }
  }, [projectId]);

  useEffect(() => {
    if (currentProject?.info && currentProject?.units && currentProject?.info?.modelURL !== "") {
      initializeMastersketch(currentProject);
      setLoading(false);
    }
  }, [currentProject, loading]);

  useEffect(() => {
    /**
     * Loads stored projects and performs necessary operations based on the current project.
     * @returns {Promise<void>} A promise that resolves when the loading and operations are completed.
     */
    const loadStoredProjects = async () => {
      setLoading(true);
      try {
        const storedProjects = await getProjects();
        const currentProject = storedProjects.find(
          (project) =>
            project.slug === projectId &&
            project.units &&
            project.units?.length > 0
        );

        if (currentProject) {
          addParams(currentProject);
          setStylesProject(currentProject?.styles);
          setPanoramaViews(currentProject?.content?.views?.view);
          currentProject?.favorites
            ? setFavs(currentProject?.favorites)
            : setFavs([]);
          currentProject?.worksheets
            ? setWorksheets(currentProject?.worksheets)
            : setWorksheets([]);

            if(currentProject?.data?.modelURL !== ""){
              await initializeMastersketch(currentProject);
            }
        } else {
          await fetchProjectUnitsData();
        }
      } catch (error) {
        console.error("Error loading stored projects:", error);
        await fetchProjectUnitsData();
      } finally {
        setLoading(false);
      }
    };

    if (projectId) {
      loadStoredProjects();
    }
  }, [projectId, fetchProjectUnitsData, addParams]);

  useEffect(() => {
    if (clickedUnit && !isComparation) {
      const selectedFp = floorplans.find((fp) => fp.fp_id === clickedUnit);
      setUnitFloor(unitFloor);
      setSelectedFloorplan(selectedFp || null);
    }
  }, [clickedUnit, floorplans, unitFloor]);

  /**
   * Toggles the favorite status of a floorplan.
   *
   * @function toggleFavorite
   * @param {string} fp_id - The ID of the floorplan.
   * @returns {void}
   * @throws {Error} - If there is an error toggling the favorite status.
   * @returns {void}
   */
  const toggleFavorite = async (fp_id) => {
    setFavs((prevFavs) => {
      const updatedFavs = prevFavs.includes(fp_id)
        ? prevFavs.filter((fav) => fav !== fp_id)
        : [...prevFavs, fp_id];
      saveOrUpdateProject({
        id: currentProject?.id || currentProject?.id_project,
        favorites: updatedFavs,
      });

      return updatedFavs;
    });
  };

  /**
   * Handles the click event for the favorites button.
   * @returns {void}
   */

  const handleFavsClick = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setShowFavs(!showFavs);
      setIsAnimating(false);
    }, 200);
  };

  const toogleWorksheets = async (f, u) => {
    if (!u) {
      const availableUnit = units.find(
        (unit) => unit.fp_id === f.fp_id && unit.unit_status === "available"
      );
      if (availableUnit) {
        f.unit = availableUnit.unit_number;
      } else {
        console.error("No available unit found");
        return; 
      }
    } else {
      f.unit = u.unit_number;
    }

    setWorksheets((prevWork) => {
      const isExisting = prevWork.some((o) => o.fp_id === f.fp_id);
      
      const updatedWorksheets = isExisting
        ? prevWork.filter((fav) => fav.fp_id !== f.fp_id) 
        : [...prevWork, f]; 
    
      saveOrUpdateProject({
        id: currentProject?.id || currentProject?.id_project,
        worksheets: updatedWorksheets,
      });
    
      if (!isExisting) {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 2000);
      }
    
      return updatedWorksheets;
    });
  };

  /**
   * Fetches the favorite units and updates the display of model availability on the master sketch.
   * @returns {void}
   *
   */
  useEffect(() => {
    const fetchFavoriteUnits = async () => {
      if (showFavs) {
        // Cuando se muestran los favoritos
        const favoriteUnitModelNames = units
          .filter(
            (unit) =>
              favorites.includes(unit.fp_id) && unit.unit_status === "available"
          )
          .map((unit) => unit.unit_model_name);

        if (favoriteUnitModelNames.length > 0) {
          setFavoritesUnits(favoriteUnitModelNames);
          await updateBuildBoxes(favoriteUnitModelNames, [], {
            available_color: sketchStyle.available_color,
            sold_color: sketchStyle.unit_color,
            available_opacity: sketchStyle.unit_opacity,
            sold_opacity: sketchStyle.unit_color,
          });
        }
        setHasViewedFavorites(true);
      } else {
        if (hasViewedFavorites) await resetBoxes();
      }
    };

    fetchFavoriteUnits();
  }, [
    showFavs,
    favorites,
    hasViewedFavorites,
    resetBoxes,
    hideSold,
    isPricing,
  ]);

  /**
   * Updates the display of model availability on the master sketch.
   *
   * @param {*} unitModelNames
   * @param {*} soldUnits
   * @param {*} styles
   */
  const updateBuildBoxes = useCallback(
    async (unitModelNames, soldUnits, styles, floors = false) => {
      if (!isMasterSketchReady) {
        console.warn("MasterSketch is not ready yet");
        return;
      }
      try {
        await masterSketchRef.current.displayModelAvailability(
          unitModelNames,
          soldUnits,
          styles,
          true,
          true,
          floors
        );
        await masterSketchRef.current.handleZoomOut();
      } catch (error) {
        console.error("Error updating build boxes:", error);
      }
    },
    [isMasterSketchReady, hideSold, isPricing]
  );

  useEffect(() => {
    const checkInterval = setInterval(() => {
      if (setIsMasterSketchReady) return;

      if (
        masterSketchRef.current &&
        typeof masterSketchRef.current.displayModelAvailability === "function"
      ) {
        setIsMasterSketchReady(true);
        console.log(`Now is ready`);
        clearInterval(checkInterval);
      }
    }, 100);
    return () => clearInterval(checkInterval);
  }, [masterSketchRef, resetBoxes, isMasterSketchReady]);

  /**
   * Closes the selected floorplan slide.
   *
   * @function closeSlide
   * @returns {void}
   */
  const closeSlide = useCallback(async () => {
    if (isComparation) {
      setIsComparation(false);
      setCompareFloorplan(null);
    }

    if (!showFavs) {
      const slideElement = document.querySelector(".selected-floorplan-slide");
      if (slideElement) {
        slideElement.classList.add("hide");
        resetBoxes();
      } else {
        setSelectedFloorplan(null);
      }
    } else {
      if (!setHasViewedFavorites) return;

      await updateBuildBoxes(favoritesUnits, [], {
        available_color: sketchStyle.available_color,
        sold_color: sketchStyle.unit_color,
        available_opacity: sketchStyle.unit_opacity,
        sold_opacity: sketchStyle.unit_color,
      });

      setSelectedFloorplan(null);
    }
  }, [
    showFavs,
    favorites,
    resetBoxes,
    favoritesUnits,
    isComparation,
    sketchStyle,
    hasViewedFavorites,
    updateBuildBoxes,
  ]);

  /**
   * Resets the filter set.
   * If the filter bar reference is available, it calls the handleReset method on the filter bar reference and then calls the handleZoomOut method on the master sketch reference.
   * If the filter bar reference is not available, it logs a warning message.
   * @returns {Promise<void>} A promise that resolves once the filter set is reset.
   */
  const resetFilterSet = async () => {
    if (filterBarRef.current && floorplans.length === 0) {
      filterBarRef.current.handleReset();
      await masterSketchRef.current.handleZoomOut();
    } else {
      console.warn("resetFilters no está definido");
    }
  };

  /**
   * Retrieves the unit information based on the given floorplan.
   *
   * @param {Object} floorplan - The floorplan object.
   * @returns {Promise<void>} - A promise that resolves when the unit information is retrieved.
   * @throws {Error} - If there is an error fetching the unit by floorplan.
   */
  const getUnitByFloorplan = async (floorplan, compare) => {
    let unitWithoutFloorplan;
    if (units.length === 0) return;
    if (!floorplates || floorplates.length === 0) {
      console.warn("No floorplates data available");
      return;
    }
    let response;
    let unitPoint = {};
    try {

      if(currentProject?.data?.modelURL === "") {

        response = units.find((unit) => unit.fp_id === floorplan.fp_id);
        response.getUnitFloor = response.unit_floor;
        response.getRenderUnit = response.unit_number;
        unitPoint = await getFloorplate(response?.unit_id);

      } else {

        response = await masterSketchRef.current.filterByFloorplanId(
        floorplan.fp_id,
        false,
        compare,
        floorsFilter
      );

      if(!response) {
        unitWithoutFloorplan = units?.find((unit) => unit.fp_id === floorplan.fp_id);
      }
      
      unitPoint = await getFloorplate(response?.getUnitId || unitWithoutFloorplan.unit_id);
    }
      
      if (compare) {
        setCompareUnitRender(response.getRenderUnit);
        setUnitCompareFloorplan(response?.getUnitFloor ||  unitWithoutFloorplan.unit_floor );
        setUnitPointCompare(unitPoint);
      } else {
        setSelectedUnitRender(response?.getRenderUnit ||  unitWithoutFloorplan.unit_floor);
        setUnitFloor(response?.getUnitFloor || unitWithoutFloorplan.unit_floor);
        setPointUnit(unitPoint);
      }
    } catch (error) {
      console.error("Error fetching unit by floorplan:", error);
    }
  };

  /**
   * Retrieves the floorplate information for a given unit ID.
   *
   * @param {string} unitId - The ID of the unit.
   * @returns {Object} - The floorplate information, including the point and floorplate image.
   */
  const getFloorplate = async (unitId) => {
    let result = {};

    if (!floorplates || floorplates.length === 0) {
      console.warn("No floorplates data available");
      return result;
    }

    floorplates.forEach((f) => {
      f.floorplate_points.point.forEach((p) => {
        const units = Array.isArray(p.floorplate_unit)
          ? p.floorplate_unit
          : [p.floorplate_unit];
        units.forEach((u) => {
          if (u.unit_id?.toString() === unitId?.toString()) {
            result = {
              point: p,
              floorplate_image: f.floorplate_image,
            };
          }
        });
      });
    });

    return result;
  };

  const activeComparation = () => {
    setIsComparation(true);
  };

  const toggleLeft = () => {
    if (isLeftExpanded) {
      setIsLeftExpanded(false);
    } else {
      setIsLeftExpanded(true);
      setIsRightExpanded(false);
    }
  };

  const toggleRight = () => {
    if (isRightExpanded) {
      setIsRightExpanded(false);
    } else {
      setIsRightExpanded(true);
      setIsLeftExpanded(false);
    }
  };

  const toggleView = (type) => {
    console.log(`Is Mobile ${type}`);
    setIsMobileView(type);
  };

  const buttonClasses = useMemo(() => ({
    helper3D:
      (isMobileDevice() &&
        (helperActive === "helper3D" || helperActive === false)) ||
      (!isMobileDevice() && !isFloorplatesView)
        ? "active"
        : "",
    helperFloorplate: helperActive === "helperFloorplate" ? "active" : "",
    helperGrid:
      (isMobileDevice() && helperActive === "helperGrid") ||
      (!isMobileDevice() && !isRowFloorplan)
        ? "active"
        : "",
    helperList:
      (isMobileDevice() && helperActive === "helperList") ||
      (!isMobileDevice() && isRowFloorplan)
        ? "active"
        : "",
  }), [isMobileDevice, isFloorplatesView, isRowFloorplan, helperActive]);

    // Handlers para botones
    const handle3DView = () => {
      setIsFloorplatesView(false);
      setHelperActive("helper3D");
      if (isMobileDevice()) toggleView(false);
    };
  
    const handleFloorplateView = () => {
      setIsFloorplatesView(true);
      setHelperActive("helperFloorplate");
      if (isMobileDevice()) toggleView(false);
    };
  
    const handleGridView = () => {
      setIsRowFloorplan(false);
      if (isMobileDevice()) {
        toggleView(isMobileView === "column" ? false : "column");
        setHelperActive("helperGrid");
      }
    };
  
    const handleListView = () => {
      setIsRowFloorplan(true);
      if (isMobileDevice()) {
        toggleView(isMobileView === "row" ? false : "row");
        setHelperActive("helperList");
      }
    };

  return (
    <>
      {loading && <Loader />}

      <FilterBar
        ref={filterBarRef}
        showFavs={showFavs}
        handleFavsClick={handleFavsClick}
        floorplans={floorplans}
        favorites={favorites}
        worksheets={worksheets}
        toggleWorksheets={toogleWorksheets}
        setFloorplans={setFloorplans}
        units={units}
        sketchStyle={sketchStyle}
        masterSketchRef={masterSketchRef}
        sketchSetup={sketchSetup}
        updateBuildBoxes={updateBuildBoxes}
        resetBoxes={resetBoxes}
        isTablet={isTablet}
        isLandscape={isLandscape}
        setFloorsFilter={setFloorsFilter}
        stylesProject={stylesProject}
        isPricing={isPricing}
        isRent={isRental}
        hideSold={hideSold}
        isFloorplanPage={true}
        currentProject={currentProject}
      />

      <div
        className={`grid ${
          isMobile || (isTablet && !isLandscape) ? "grid-cols-1" : "grid-cols-2"
        } gap-2 h-screen 
        ${
          isLeftExpanded
            ? "column-expanded-left"
            : isRightExpanded
            ? "column-expanded-right"
            : isMobile || isTablet
            ? "column"
            : "column-centered"
        }`}
      >
        <div
          className={`auto-cols-auto render ${
            isMobile || isTablet ? "h-screen" : ""
          }`}
          style={{
            display: isFloorplatesView ? "none" : "flex",
          }}
        >
          <LegendBar
            sketchStyle={sketchStyle}
            updateBuildBoxes={updateBuildBoxes}
            resetBoxes={resetBoxes}
            masterSketchRef={masterSketchRef}
            unitRender={selectedUnitRender}
            floorplan={selectedFloorplan}
            currentProject={currentProject}
            isLeftExpanded={isLeftExpanded}
            isRightExpanded={isRightExpanded}
            hideSold={hideSold}
            styles={stylesProject}
            isRent={parseInt(isPricing)}
          />

          <iframe
            key={`sketchfab-${modelUrl}`}
            ref={iframeRef}
            id="api-frame"
            width="100%"
            height="100%"
            src={modelUrl}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; xr-spatial-tracking"
            title="Sketchfab Viewer"
            className={`${isFloorplatesView ? "hidden" : ""}`}
          />
         
        </div>

        <div
          className="auto-cols-auto floorplates"
          style={{ display: isFloorplatesView ? "flex" : "none" }}
        >
          
          <FloorplatesView
            floorplates={floorplates}
            floorplans={floorplans}
            units={units}
            sketchStyle={sketchStyle}
            setSelectedUnitRender={setSelectedUnitRender}
            setSelectedFloorplan={setSelectedFloorplan}
            setUnitFloor={setUnitFloor}
            setPointUnit={setPointUnit}
            selectedFloorplan={selectedFloorplan}
          />
        </div>
        {!isMobile && !isTablet && (
            <button
              className={`button-slide slide-to-left absolute top-1/2 left-0 transform -translate-y-1/2  ${
                isRightExpanded ? "active" : ""
              }`}
              onClick={() => toggleRight()}
              style={{
                display: isLeftExpanded || isComparation ? "none" : "block",
                left: isRightExpanded ? "1%" : "48.4%",
              }}
            >
              <ChevronLeftIcon
                className="size-6"
                style={{
                  transform: isRightExpanded
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                }}
              />
            </button>
          )}

        {!isMobile && !isTablet && (
          <button
            className={`button-slide slide-to-right absolute top-1/2 right-0 transform -translate-y-1/2 ${
              isLeftExpanded ? "active" : ""
            }`}
            onClick={() => toggleLeft()}
            style={{
              display: isRightExpanded || isComparation || isFloorplatesView ? "none" : "block",
              left: isLeftExpanded ? "98.4%" : "48.4%",
            }}
          >
            <ChevronRightIcon
              className="size-6"
              style={{
                transform: isLeftExpanded ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </button>
        )}
        <GridFloorplans
          isMobileView={isMobileView}
          floorplans={floorplans}
          resetFilterSet={resetFilterSet}
          isRowFloorplan={isRowFloorplan}
          isTablet={isTablet}
          isRightExpanded={isRightExpanded}
          showFavs={showFavs}
          isAnimating={isAnimating}
          favorites={favorites}
          compareFloorplan={compareFloorplan}
          selectedFloorplan={selectedFloorplan}
          worksheets={worksheets}
          toggleFavorite={toggleFavorite}
          toogleWorksheets={toogleWorksheets}
          stylesProject={stylesProject}
          isPricing={isPricing}
          typeColors={typeColors}
          has360={has360}
          isComparation={false}
          setCompareFloorplan={false}
          getUnitByFloorplan={getUnitByFloorplan}
          setSelectedFloorplan={setSelectedFloorplan}
        />

        {isComparation && (
          <GridFloorplans
            isMobileView={isMobileView}
            floorplans={floorplans}
            resetFilterSet={resetFilterSet}
            isRowFloorplan={isRowFloorplan}
            isTablet={isTablet}
            isRightExpanded={isRightExpanded}
            showFavs={showFavs}
            isAnimating={isAnimating}
            favorites={favorites}
            compareFloorplan={compareFloorplan}
            selectedFloorplan={selectedFloorplan}
            worksheets={worksheets}
            toggleFavorite={toggleFavorite}
            toogleWorksheets={toogleWorksheets}
            stylesProject={stylesProject}
            isPricing={isPricing}
            typeColors={typeColors}
            has360={has360}
            isComparation={true}
            setCompareFloorplan={setCompareFloorplan}
            getUnitByFloorplan={getUnitByFloorplan}
            setSelectedFloorplan={setSelectedFloorplan}
          />
        )}

        {selectedFloorplan && unitFloor && (
          <SelectedFloorplanSlide
            floorplan={selectedFloorplan}
            onClose={closeSlide}
            unitRender={selectedUnitRender}
            setSelectedUnitRender={setSelectedUnitRender}
            isFavorite={favorites.includes(selectedFloorplan.fp_id)}
            floorUnit={unitFloor}
            pointForUnit={pointUnit}
            getFloorplate={getFloorplate}
            setPointUnit={setPointUnit}
            styleProject={stylesProject}
            toggleFavorite={() => toggleFavorite(selectedFloorplan.fp_id)}
            units={units}
            masterSketchRef={masterSketchRef}
            views={panoramaViews}
            activeComparation={activeComparation}
            filterBarRef={filterBarRef}
            contactEmail={currentProject.content.contact_mail}
            floorsFilter={floorsFilter}
            externalForm={externalForm}
            sketchStyle={sketchStyle}
            displayPrices={currentProject.data?.display_prices}
            isActiveComparation={isComparation}
            isLeftExpanded={isLeftExpanded}
            setIsLeftExpanded={setIsLeftExpanded}
            setUnitFloor={setUnitFloor}
            //  setIsFullScreen={setIsFullScreen}
          />
        )}

        {compareFloorplan && (
          <CompareFloorplanSlide
            compareFloorplan={compareFloorplan}
            compareUnitRender={compareUnitRender}
            setCompareUnitRender={setCompareUnitRender}
            isFavorite={favorites.includes(compareFloorplan.fp_id)}
            unitCompareFloorplan={unitCompareFloorplan}
            pointForUnit={unitPointCompare}
            styleProject={stylesProject}
            toggleFavorite={() => toggleFavorite(compareFloorplan.fp_id)}
            units={units}
            activeComparation={activeComparation}
            contactEmail={currentProject.content.contact_mail}
            externalForm={externalForm}
            sketchStyle={sketchStyle}
            displayPrices={currentProject.data?.display_prices}
            btnReqInfo={currentProject.data?.btn_req_info}
          />
        )}

        <ViewButtons
          isLeftExpanded={isLeftExpanded}
          isRightExpanded={isRightExpanded}
          isMobileView={isMobileView}
          toggleLeft={toggleLeft}
          buttonClasses={buttonClasses}
          handle3DView={handle3DView}
          handleFloorplateView={handleFloorplateView}
          handleGridView={handleGridView}
          handleListView={handleListView}
          modelUrl={currentProject?.info?.modelURL}
        />

        {showAlert && (
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-gray-500 text-white px-4 py-2 rounded-lg shadow-lg">
            Worksheet added successfully!
          </div>
        )}
      </div>
     
    </>
  );
};

export default FloorPlans;
