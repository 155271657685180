import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import slugify from "react-slugify";
import { isMobileDevice } from "../../../../plugins/MobileDetect";
import "./FloatMenu.css";

const FloatMenu = ({
  favorites = [],
  worksheets,
  isRent,
  handleFavsClick,
  isFloorplanPage,
  setOpenSearch,
  setOpenMobile,
  openSearch,
  openMobile,
  floorplanPage,
  toggleWorksheets,
  projectType,
  showWorksheets
}) => {
  const [openFavorites, setOpenFavorites] = useState(false);
  const [openWorksheets, setOpenWorksheets] = useState(false);
  const [worksheetsOrder, setWorksheetsOrder] = useState([]);

  const navigate = useNavigate();
  const { projectId } = useParams();

  useEffect(() => {
    console.log(showWorksheets)
    setWorksheetsOrder(worksheets?.map((w) => w.fp_id));
  }, [worksheets, showWorksheets]);

  const handleFavoritesClick = () => {
    handleFavsClick ? handleFavsClick() : navigateToFloorplan();
    setOpenFavorites(!openFavorites);
  };

  const navigateToFloorplan = () => {
    navigate(`/project/${projectId}/${slugify(floorplanPage.title)}`);
  };

  const handleSearchClick = () => {
    if (!isFloorplanPage) {
      navigateToFloorplan();
    } else {
      if (isMobileDevice()) {
        setOpenMobile(!openMobile);
      } else {
        setOpenSearch(!openSearch);
      }
    }
  };

  const handleReorder = (index, newIndex) => {
    if (index === newIndex) return;

    const updatedOrder = [...worksheetsOrder];
    const [movedItem] = updatedOrder.splice(index, 1);
    updatedOrder.splice(newIndex, 0, movedItem);
    setWorksheetsOrder(updatedOrder);
  };

  return (
    <div id="secMenu">
      <div
        id="favsIcon"
        className={`secIcon ${openFavorites ? "active" : ""}`}
        onClick={handleFavoritesClick}
      >
        <div id="favsCount">{favorites.length}</div>
      </div>

      {!isRent && projectType !== "LOWRISE" && showWorksheets !== "0" && (
        <div
          id="quickAddWs"
          className={`secIcon unitsQuickAdd ${
            openWorksheets ? "active show on" : ""
          }`}
          onClick={() => setOpenWorksheets(true)}
        >
          <div id="wsCount">{worksheets?.length || 0}</div>
          <div id="quickAddContainer" className="stylesQuickAddCont">
            <div
              id="closeQuickContainer"
              onClick={(e) => {
                e.stopPropagation();
                setOpenWorksheets(false);
              }}
            ></div>

            {worksheets?.length === 0 ? (
              <div className="innerContainerQuickAdd">
                <p className="noUnitsText">
                  No floor plans added <br /> to your worksheet
                </p>
              </div>
            ) : (
              <>
                {worksheetsOrder?.map((worksheetId, i) => {
                  const worksheet = worksheets?.find(
                    (w) => w.fp_id === worksheetId
                  );

                  return (
                    <div
                      key={worksheet.fp_id}
                      className="unitQuickAdd showDrop"
                    >
                      <div
                        className="imgQuickUnit"
                        style={{
                          backgroundImage: `url(${worksheet.fp_image_low?.[0]})`,
                        }}
                      ></div>

                      <div className="infoQuickUnit">
                        <h5 className="titleFont titleQuickUnit titleCardsColors">
                          {worksheet.fp_name}
                        </h5>
                        <p className="textUnitQuick">UNIT {worksheet.unit}</p>

                        <div className="floorplanPreviewLeft">
                          <div className="floorplanContentRow floorplanContentRow-1">
                            <span className="smallFeatureTitle">Beds</span>
                            {worksheet.fp_bedrooms}
                          </div>

                          <div className="floorplanContentRow floorplanContentRow-2">
                            <span className="smallFeatureTitle">Baths</span>
                            {Math.round(worksheet.fp_bathrooms)}
                          </div>

                          <div
                            className="closeUnitQuick"
                            onClick={() => toggleWorksheets(worksheet, false)}
                          ></div>
                        </div>
                      </div>

                      {/* 🔹 Select para reordenar los worksheets */}
                      <select
                        className="quickDrops"
                        value={i}
                        onChange={(e) =>
                          handleReorder(i, parseInt(e.target.value))
                        }
                      >
                        {worksheetsOrder.map((_, index) => (
                          <option
                            key={index}
                            value={index}
                            className="uppercase"
                          >
                            {index + 1}° CHOICE
                          </option>
                        ))}
                      </select>
                    </div>
                  );
                })}

                <a
                  href="undefined/index?modelIds=6177027ae2c56274f9d3a624^617703c7e2c56274f9d3a62c"
                  id="externalWorksheetBtn"
                >
                  Submit Worksheet
                </a>
              </>
            )}
          </div>
        </div>
      )}

      <div
        id="searchIcon"
        className={`secIcon ${isFloorplanPage && !openSearch ? "active" : ""}`}
        onClick={handleSearchClick}
      >
        <div className="signAddWs titleFont"></div>
      </div>
    </div>
  );
};

export default FloatMenu;
