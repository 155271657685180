import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useIframe } from "../../../contexts/IframeContext";

const SiteplanLowrise = () => {
  const { projectId } = useParams();
  const { setProjectId, setVisible } = useIframe();

  useEffect(() => {
    setProjectId(projectId);
    setVisible(true); 

    return () => {
      setVisible(false); 
    };
  }, [projectId, setProjectId, setVisible]);

  return <div className="flex h-screen relative"></div>;
};

export default SiteplanLowrise;
