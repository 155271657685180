import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { useEffect, useRef, useState } from "react";
import "@splidejs/react-splide/css";
import "./style.css";

const HalfGallery = ({ section, sectionIndex, subsectionIndex }) => {
  const [checkSubsection, setCheckSubsection] = useState(false);
  
  // Slider refs
  const descriptionRef = useRef(null);
  const imageRef = useRef(null);

  // Slider navigation
  const handlerNavigation = (type) => {
    const descriptionsSlider = descriptionRef.current?.splide;
    if (!descriptionsSlider) return;

    if (type === "prev") {
      descriptionsSlider.go("<");
    } else {
      descriptionsSlider.go(">");
    }
  };

  useEffect(() => {
    if (section) {

      const descriptionsSlider = descriptionRef.current?.splide;
      const imageSlider = imageRef.current?.splide;

      if (descriptionsSlider && imageSlider) {
        descriptionsSlider.on("move", (newIndex) => imageSlider.go(newIndex));
        descriptionsSlider.on("drag", () => imageSlider.go(descriptionsSlider.index));
        imageSlider.on("move", (newIndex) => descriptionsSlider.go(newIndex));
      }
      setCheckSubsection(true);
    }
  }, [section]);

  return (
    <>
      {checkSubsection ? (
        <main className="w-full h-full flex items-center justify-center" id="half__gallery">
          <section className="h-full w-full md:h-[80%] md:w-[80%] grid md:grid-cols-2 grid-rows-2 md:grid-rows-1 half__gallery__container">
            {/* Descripción Slider */}
            <Splide
              ref={descriptionRef}
              hasTrack={false}
              aria-label="description_content"
              className={`txtBgColor_${sectionIndex}_${subsectionIndex} flex items-center justify-center w-full md:w-auto relative`}
              options={{
                pagination: false,
                gap   : '1rem',
                rewind: false,
                arrows: false,
                drag: section?.descriptions && section?.descriptions?.length > 1,
              }}
            >
              {/* Arrows */}
              {section?.descriptions && section?.descriptions.length > 1 && (
                <div id="arrows_dots" className="absolute z-50 flex gap-3 bottom-5 right-7 text-3xl">
                  <span
                    onClick={() => handlerNavigation("prev")}
                    className={`slick-next slick-arrow arrowLeft_${sectionIndex}_${subsectionIndex} hover:scale-125 cursor-pointer transition-all ease-linear bg-no-repeat bg-cover size-6`}
                  ></span>
                  <span
                    onClick={() => handlerNavigation("next")}
                    className={`slick-next slick-arrow arrowRight_${sectionIndex}_${subsectionIndex} hover:scale-125 cursor-pointer transition-all ease-in-out bg-no-repeat bg-cover size-6`}
                  ></span>
                </div>
              )}

              <SplideTrack>
                {section?.descriptions?.map((desc, index) => (
                  <SplideSlide key={index} className={`half__gallery__text flex flex-col gap-3 w-full flex-shrink-0 txtBgColor_${sectionIndex}_${subsectionIndex} `}>

                    <div className="middle half__gallery__text-item ">
                      {desc?.description?.image && (
                        <div className="image__description flex w-full justify-center">
                            <img className="half__gallery__text-topImage topImage-medium pt-8 flex  w-auto" src={desc?.description?.image?.value} alt="" />
                        </div>

                        
                      )}
                      <h2 className={`half__gallery__text-title titleFont titleColor_${sectionIndex}_${subsectionIndex} `}>
                        <p> {desc?.description?.description_title}</p>
                      </h2>
                      <p className={`half__gallery__text-subtitle subtitleFont subtitleColor_${sectionIndex}_${subsectionIndex}`}>
                        {desc?.description?.description_subtitle}
                      </p>
                      {section?.dividerLine === "1" && (
                        <div className={`half__gallery__text-divider dividingLine_${sectionIndex}_${subsectionIndex}`}></div>
                      )}
                      
                      <div className="half__gallery__text-content">
                        <p className={`half__gallery__text-text textColor_${sectionIndex}_${subsectionIndex}`}>
                          {desc?.description?.description_texts}
                        </p>
                        {desc?.description?.description_cta?.subsection >= 0 && (
                          <a href={`./${desc?.description?.description_cta?.slug}`} className={`content_cta ctaColor_${sectionIndex}_${subsectionIndex}`}>{desc?.description?.description_cta?.value}</a>
                        )}
                        
                      </div>
                    </div>
                  </SplideSlide>
                ))}
              </SplideTrack>
            </Splide>

            {/* Imagen Slider */}
            <Splide
              hasTrack={false}
              className="row-start-1 md:row-start-auto"
              aria-label="images_content"
              options={{ pagination: false, arrows: false, type: "fade", rewind: true, drag: false, speed: 700 }}
              ref={imageRef}
            >
              <SplideTrack className="h-full">
                {section?.halfimages?.image?.map((imageObject, index) => (
                  <SplideSlide key={`image_${index}`}>
                    <figure className="h-full">
                      <img className="object-cover h-full w-full" src={imageObject?.value} alt="" />
                    </figure>
                  </SplideSlide>
                ))}
              </SplideTrack>
            </Splide>
          </section>
        </main>
      ) : (
        <h3>Loading data...</h3>
      )}
    </>
  );
};

export default HalfGallery;

