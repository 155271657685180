import React, { useEffect } from 'react';
import { isMobileDevice } from '../../../../plugins/MobileDetect';


const ViewButtons = ({
  isLeftExpanded,
  isRightExpanded,
  isComparation,
  isFloorplatesView,
  isRowFloorplan,
  helperActive,
  handle3DView,
  handleFloorplateView,
  handleGridView,
  handleListView,
  modelUrl
}) => {

  useEffect(() => {
    if(modelUrl === "") {
      handleFloorplateView();
    }
  }, [modelUrl, handleFloorplateView]);
  const buttonClasses = {
    helper3D:
      (isMobileDevice() &&
        (helperActive === "helper3D" || helperActive === false)) ||
      (!isMobileDevice() && !isFloorplatesView)
        ? "active"
        : "",
    helperFloorplate: helperActive === "helperFloorplate" ? "active" : "",
    helperGrid:
      (isMobileDevice() && helperActive === "helperGrid") ||
      (!isMobileDevice() && !isRowFloorplan)
        ? "active"
        : "",
    helperList:
      (isMobileDevice() && helperActive === "helperList") ||
      (!isMobileDevice() && isRowFloorplan)
        ? "active"
        : "",
  };

  return (
    <div className="buttons-container">
      <div
        id="helpersLeft"
        className={`view-options`}
        style={{
          display:
            isLeftExpanded || isRightExpanded
              ? "none"
              : isMobileDevice()
              ? "contents"
              : "block",
          height: modelUrl !== "" ? "60px" : "38px",
        }}
      >
        {modelUrl !== "" && (
          <button
            onClick={handle3DView}
            className={`btn btn-primary helper helper3D parentTooltip ${buttonClasses.helper3D}`}
          />
        )}

        <button
          onClick={handleFloorplateView}
          className={`btn btn-primary helper helperFloorplate parentTooltip ${buttonClasses.helperFloorplate}`}
        />
      </div>

      <div
        className="change-view helper"
        style={{
          display: isLeftExpanded || isRightExpanded ? "none" : "block",
        }}
      >
        <button
          onClick={handleGridView}
          className={`btn btn-primary helper helperGrid parentTooltip ${buttonClasses.helperGrid}`}
        />
        <button
          onClick={handleListView}
          className={`btn btn-primary helper helperList parentTooltip ${buttonClasses.helperList}`}
        />
      </div>
    </div>
  );
};

export default ViewButtons;