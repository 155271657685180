import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import slugify from "react-slugify";
import Slider from "../Templates/_Parts/Slide";
import MasonryGallery from "../Templates/_Parts/GalleryMasonry";
import SliderViews from "../Templates/_Parts/SliderViews";
import FullImageSize from '../Templates/_Parts/ImageFullScreen';
import HalfGallery from '../Templates/_Parts/HalfGallery';
import Tour360 from '../Templates/360Tour';
import Columns from '../Templates/_Parts/Columns';

const Subpage = ({ favorites, worksheets, sectionContent, colors,  isRent, floorplanPage }) => {
 
  const { sectionSlug, subsectionSlug } = useParams();

  const section = sectionContent?.find(
    (section) => slugify(section.title) === sectionSlug
  );

  const subsection = section?.subsection?.find(
    (subitem) => slugify(subitem.titleSubsection) === subsectionSlug
  );

  useEffect(() => {
    if (subsection) {
      document.title = `${section.title} - ${subsection.titleSubsection}`;
    }
  }, [section, subsection]);
  if (!subsection) return <p>Subsection not found</p>;

  return (
    <>
      {subsection?.template === "floatSquare" && subsection?.floatSquare_item && (
        <Slider pattern={section?.index} content={subsection} colors={colors} sugpage={sectionSlug} sectionContent={sectionContent} />
      )}

      {subsection?.template === "masonry" &&
        subsection?.images?.length > 0 && (
          <MasonryGallery images={subsection?.images} />
      )}

      {subsection?.template === "slideshow" && (
        <SliderViews images={ subsection?.images} sectionIndex={section?.index} subsectionIndex={subsection.indexSubsection} />
      )}

      {subsection?.template === 'amenities' && (
       <FullImageSize url={subsection?.amenities?.amenitie}/>
      )}

      {subsection?.template === 'halfGallery' && (
       <HalfGallery section={subsection} sectionIndex={section?.index} subsectionIndex={subsection.indexSubsection} colors={colors} />
      )}

      {subsection?.template === '360_tour' && (
       <Tour360 section={subsection} sectionIndex={section?.index} subsectionIndex={subsection.indexSubsection} colors={colors} />
      )}

      {subsection?.template === 'columns' && (
       <Columns section={subsection} sectionIndex={section?.index} subsectionIndex={subsection.indexSubsection} colors={colors} />
      )}
    </>
  );
};

export default Subpage;
