import { React } from "react";
import FloorplanItem from "./FloorplanItem";
import "../_styles/GridFloorplans.css";

const GridFloorplans = ({
  isMobileView,
  floorplans,
  resetFilterSet,
  isRowFloorplan,
  showFavs,
  isAnimating,
  favorites,
  compareFloorplan,
  isRightExpanded,
  selectedFloorplan,
  worksheets,
  toggleFavorite,
  toogleWorksheets,
  stylesProject,
  isPricing,
  typeColors,
  has360,
  isComparation,
  setCompareFloorplan,
  getUnitByFloorplan,
  setSelectedFloorplan,
}) => {
  return (
    <div
      className={`auto-cols-auto block floorplans ${isComparation ? "absolute" : ""} ${
        isMobileView ? "show" : "hide"
      } `}
    >
      <div className={`grid grid-cols-1 gap-4 right`}>
        <p className="justify-end flex mt-20">
          {floorplans.length} Suites match your search{" "}
        </p>
      </div>
      <div
          className={`grid gap-4 mt-1 floorplans-grid sm:grid-cols-1 
            ${isRowFloorplan 
              ? "md:grid-cols-1 2xl:grid-cols-1 row-view" 
              : "md:grid-cols-2 2xl:grid-cols-3 grid-view"
            }
            ${isRightExpanded ? "right-expanded" : ""}
            `}
          
>
        {floorplans.length === 0 ? (
          <div className="grid not-found">
            <h2>No results found</h2>
            <button onClick={resetFilterSet} className="btn btn-primary">
              Reset Filters
            </button>
          </div>
        ) : (
          floorplans
            .sort((a, b) => a.fp_name.localeCompare(b.fp_name))
            .filter(
              (floorplan) => !showFavs || favorites.includes(floorplan.fp_id)
            )
            .map((floorplan, i) =>
              floorplan.fp_status.toString() === "available" ? (
                <div
                  key={floorplan.fp_id + i}
                  className={`floorplan-item ${isAnimating ? "hide" : "show"}`}
                >
                  <FloorplanItem
                    key={floorplan.fp_id + i}
                    floorplan={floorplan}
                    isRowFloorplan={isRowFloorplan}
                    isSelected={
                      !compareFloorplan &&
                      selectedFloorplan?.fp_id === floorplan.fp_id
                    }
                    floorUnit={null}
                    isFavorite={favorites.includes(floorplan.fp_id)}
                    isWorksheet={worksheets.find(
                      (f) => f.fp_id === floorplan.fp_id
                    )}
                    toggleFavorite={() => toggleFavorite(floorplan.fp_id)}
                    toogleWorksheets={() => toogleWorksheets(floorplan)}
                    styleProject={stylesProject}
                    isPricing={isPricing}
                    typeColors={typeColors}
                    has360={has360}
                    icons={
                      stylesProject?.ws_panel.find(
                        (item) => item.at === ".addToWs"
                      )?.["background-image"]
                    }
                    onClick={() => {
                      if (isComparation) {
                        setCompareFloorplan(floorplan);
                        getUnitByFloorplan(floorplan, true);
                      } else {
                        setSelectedFloorplan(floorplan);
                        getUnitByFloorplan(floorplan, false);
                      }
                    }}
                  />
                </div>
              ) : null
            )
        )}
      </div>
    </div>
  );
};

export default GridFloorplans;
