import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./FullImageSize.css";

const FullImageSize = ({ url }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeImage, setActiveImage] = useState(0);
  const [openBox, setOpenBox] = useState(false);

  const [dimensions, setDimensions] = useState({
    width: 1920,
    height: 1080,
    top: 0,
    left: 0,
  });

  // Detectar si el usuario está en un móvil o tablet
  const isMobileDevice = () => {
    return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  };

  const updateDimensions = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const maxHeight = windowHeight - 100;

    const aspectRatio = 16 / 9; // Ajustar según la imagen real

    let imgWidth, imgHeight;

    if (isMobileDevice()) {
      // Para móviles y tablets
      imgWidth = windowWidth - 40; // Margen reducido en móviles
      imgHeight = imgWidth / aspectRatio;
    } else {
      // Para escritorio
      imgWidth = windowWidth - 450;
      imgHeight = Math.min(imgWidth / aspectRatio, maxHeight);
    }

    setDimensions({
      width: imgWidth,
      height: imgHeight,
      left: (windowWidth - imgWidth) / 2,
      top: (windowHeight - imgHeight) / 2,
    });
  };

  const openAmenitie = (image) => {
    setActiveImage(image);
    setOpenBox(true);
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  if (Array.isArray(url)) {
    return (
      <>
        <section id="amenities" style={{ display: "block" }}>
          {/* Selector Mobile */}
          <select
            name="amenitiesSelector"
            className="amenities__selector__mobile"
            id="amenitiesMobile"
            onChange={(e) => setActiveIndex(Number(e.target.value))}
          >
            {url.map((image, index) => (
              <option value={index} key={index}>
                {image.name}
              </option>
            ))}
          </select>

          {/* Selector Desktop */}
          {url.length > 1 && (
            <div className="amenities__selector">
              {url.map((image, index) => (
                <div
                  key={index}
                  id={`amenities__selector-item-${index}`}
                  className={`amenities__selector-item amenities_selector ${
                    index === activeIndex ? "active" : ""
                  }`}
                  data-index={index}
                  onClick={() => setActiveIndex(index)}
                >
                  {image.name}
                </div>
              ))}
            </div>
          )}

          {/* Contenedor de imágenes y dots */}
          <div className="amenities__container">
            {url.map((image, index) => (
              <div
                key={index}
                className={`amenities-item ${
                  index === activeIndex ? "active" : ""
                }`}
                id={`amenitie-${index}`}
              >
                <img
                  className="amenities__container-img"
                  id={`amenitie_img-${index}`}
                  src={image.amenitieImg}
                  alt={image.name}
                  style={{
                    width: `${dimensions.width}px`,
                    height: `${dimensions.height}px`,
                    top: `50%`,
                    left: `50%`,
                    transform: "translate(-50%, -50%)",
                    position: "absolute",
                  }}
                />
                <div
                  className="amenities__container-dots"
                  id={`amenities_dots-${index}`}
                  style={{
                    width: `${dimensions.width}px`,
                    height: `${dimensions.height}px`,
                  }}
                >
                  {image?.dots?.map((dot, dotIndex) => (
                    <div
                      key={dotIndex}
                      className="amenities__dot amenities__dot-image"
                      data-img={dot?.dot[0]}
                      data-num={dotIndex}
                      data-type={dot.dot?.type}
                      style={{
                        top: `${dot.dot?.top}%`,
                        left: `${dot.dot?.left}%`,
                      }}
                      onClick={() =>
                        openAmenitie(
                          image?.dotsAmenities?.[`${dotIndex}`]?.dot?.urlMedium
                        )
                      }
                    >
                      <div className="amenitiesIconBG"></div>
                      <div className="amenitiesIcon amenitiesIconPic"></div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div
            className={`amenitie__open ${openBox ? "active" : ""}`}
            style={{ backgroundImage: `url(${activeImage})` }}
          >
            <div className="close" onClick={() => setOpenBox(false)}></div>
          </div>
          <div
            id="smallPop"
            style={{ display: activeImage ? "block" : "none" }}
          >
            <div id="smallPopTitle" className="titleFont"></div>
            <div id="smallPopContent"></div>
            <div id="closeSmallPop" onClick={() => setOpenBox(false)}></div>
          </div>
        </section>
      </>
    );
  } else {
    return (
      <div classNameName="image-container">
        <img src={url} alt="full-screen" />
      </div>
    );
  }
};

FullImageSize.propTypes = {
  url: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        amenitieImg: PropTypes.string.isRequired,
        name: PropTypes.string,
      })
    ),
  ]).isRequired,
};

export default FullImageSize;
