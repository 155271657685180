/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import slugify from "react-slugify";
import {  getStyleObjectFromString } from "../../../../plugins/FormatStyle.js";
import { getProjectBySlug } from "../../../../db.js";
import "./gallery.css";
import { isMobileDevice, isPortrait } from "../../../../plugins/MobileDetect.js";


const FullScreenSlider = ({ pattern, content, colors, sugpage, section }) => {
  const { projectId } = useParams();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sectionsPage, setSectionsPage] = useState([]);

  useEffect(() => {
    const getProject = async () => {
      const project = await getProjectBySlug(projectId);
      setSectionsPage(project?.content?.section);
    };

    getProject();
    
  }, [projectId, section, pattern]);

  const slides = useMemo(() => {
    return Array.isArray(content?.floatSquare_item)
      ? content.floatSquare_item
      : [content.floatSquare_item];
  }, [content]);

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  // Calcula los estilos una sola vez
  const textStyle = useMemo(
    () => getStyleObjectFromString(colors?.textColor_0_0 || ""),
    [colors]
  );

  const linkButtonSection = (sectionId, indexSub, sugpage) => {
    const section = sectionsPage?.find((item) => item.id === parseInt(sectionId));
    const subsection = section?.subsection?.find((item) => item.indexSubsection === parseInt(indexSub));
    const response = !sugpage 
        ? parseInt(indexSub) >= 0 && parseInt(sectionId) > 0 && section?.subsection?.length > 1
            ? `${slugify(section?.title)}/${slugify(subsection?.titleSubsection)}` || ''
            : slugify(section?.title) || ''
        : slugify(subsection?.titleSubsection || '');

    return response;
    
  };

  const getSectionButtonName = (sectionId, indexSub) => {
    const section = sectionsPage?.find((item) => item.id === parseInt(sectionId));
    return section?.title || '';
  };

  const RenderSlideContent = (item) => {

    const {
      floatSquare_title: title,
      floatSquare_subtitle: subtitle,
      floatSquare_text: text,
      floatSquare_button: button,
      logo,
    } = item.floatSquare_content;

    return (
<div id="float__square">
  <div
    className={`float__square__content text-slide flex-wrap ${!item?.textboxbg ? `txtBgColor_${pattern}_${content?.indexSubsection}` : ""} boxOpacity ${
      item?.textboxPosition && item?.textboxPosition !== "Array"
        ? item?.textboxPosition.toLowerCase()
        : "left"
    }`}
    style={{
      ...(item.textboxbg
        ? {
            backgroundImage: `url(${item.textboxbg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            opacity: colors?.opacitytxbx[0].opacity || 1,
          }
        : {}),
     
    }}
  >
    {logo?.urlMedium && !title ? (
      <img
        className={`half__gallery__text-topImage topImage-undefined logo-slide ${item?.floatSquare_content?.floatSquare_logo_align}`}
        src={logo.urlMedium}
        alt="Logo"
      />
    ) : (
      <>
        <h2
          className={`float__square__content-title titleFont titleColor_${pattern}_${content?.indexSubsection}`}
        >
          <p dangerouslySetInnerHTML={{ __html: title }}></p>
        </h2>
        
      </>
    )}
    {subtitle && (
        <p
        className={`float__square__content-subtitle subtitleFont subtitleColor_${pattern}_${content?.indexSubsection}`}
        dangerouslySetInnerHTML={{ __html: subtitle }}
      ></p>
    )}

    {text && (
      <p
        className={` float__square__content-text textColor_${pattern}_${content?.indexSubsection}`}
        style={textStyle}
      >
        {text}
      </p>
    )}

    {typeof button?.["floatSquare_button-title"]["value"] === "string" && (
      <Link
        to={`/project/${projectId}${
          sugpage ? `/${sugpage}/` : "/"
        }${linkButtonSection(
          button["floatSquare_button-title"]["section"],
          button["floatSquare_button-title"]["subsection"],
          sugpage
        )}`}
        className={`float__square__content-cta content_cta ctaColor_${pattern}_${content?.indexSubsection}`}
      >
        {button["floatSquare_button-title"]["value"] || getSectionButtonName(
          button["floatSquare_button-title"]["section"])}
      </Link>
    )}

    {slides.length > 1 && (
      <div className="arrows absolute bottom-0 right-0">
        <button
          onClick={prevSlide}
          className={`arrow bottom-0 right-12 text-white arrowLeft_${pattern}_${content?.indexSubsection}`}
          aria-label="Previous Slide"
        ></button>

        <button
          onClick={nextSlide}
          className={`arrow bottom-0 right-0 text-white arrowRight_${pattern}_${content?.indexSubsection}`}
          aria-label="Next Slide"
        ></button>
      </div>
    )}
  </div>
</div>


    );
  };

  return (
        <div className={`w-full h-screen ${isMobileDevice() ? "overflow-auto" : "overflow-hidden"} relative isMobile-${isMobileDevice()}}`}>
          {slides.length > 0 &&
            slides.map((item, index) => {
              const isMobile = isMobileDevice();
              const isActive = index === currentSlide ? "opacity-100" : "opacity-0";
              const backgroundImage = isPortrait()  ? item.floatSquare_mobile : item.floatSquare_bg;
              // const backgroundSize = isPortrait() ? "cover" : "contain";

              return (
                <div 
                  key={index} 
                  className={`absolute inset-0 transition-opacity duration-1000 isMobile-${isPortrait()} ${isActive}  isPortrait-${isPortrait()}`}
                  style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: "center center",
                    backgroundRepeat: !isMobile ? "no-repeat" : undefined,
                  }}
                >
                  {isMobile ? (
                    <div className="content" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center center" }} />
                  ) : null}
                  {RenderSlideContent(item)}
                </div>
              );
            })}
        </div>

  );
}  

export default FullScreenSlider;
