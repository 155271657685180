/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import RequestInfoForm from "./RequestInfoForm";
import IframeLightbox from "../../_Parts/LightBox/iframe";
import "../_styles/CompareFloorplanSlide.css";

const CompareFloorplanSlide = ({
  compareFloorplan,
  compareUnitRender,
  isFavorite,
  floorUnit,
  pointForUnit,
  styleProject,
  toggleFavorite,
  unitCompareFloorplan,
  contactEmail,
  sketchStyle,
  displayPrices,
  externalForm,
  btnReqInfo,
  units
}) => {
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [unitOri, setUnitOri] = useState("");
  const [showSlider, setShowSlider] = useState(true);
  const [othersUnits, setOthersUnits] = useState([]);
  const openModal = () => setShowRequestModal(true);
  const closeModal = () => setShowRequestModal(false);

  /**
   * Filters and sorts units based on the given floorplan ID and their availability status.
   *
   * @param {number} floorplanId - The ID of the floorplan to filter units by.
   * @returns {void} This function does not return a value. It sets the state with the filtered and sorted units.
   */
  const getOthersUnits = (floorplanId) => {
    let othersUnits = units?.filter(
      (unit) =>
        unit.fp_id === floorplanId &&
        unit.unit_status === "available" 
      
    );

    let orderUnits = othersUnits?.sort((a, b) =>
      parseInt(a.unit_name) > parseInt(b.unit_name) ? 1 : -1
    );
    setOthersUnits(orderUnits);
  };

  
  useEffect(() => {
    getOthersUnits(compareFloorplan.fp_id);
    setShowSlider(true);
  }, [compareFloorplan, unitOri]);

  return (
    <div className={`compared-floorplan-slide ${showSlider ? "show" : "hide"}`}>
      <div
        id="floorplanImagePreview"
        className="floorplanImageView floorplan-img"
      >
        <img
          src={compareFloorplan.fp_image_med[0]}
          alt={compareFloorplan.fp_name}
          className="full-size-image"
        />
      </div>
      <div id="floorplanPreviewContainer">
        {pointForUnit && pointForUnit.point && (
          <div id="floorplanPreviewFloorplate" className="floorplanFloorplate">
            <div className="floorplate previewFloorplate">
              <div className="floorplateInner">
                <img
                  className="floorplateImg"
                  src={pointForUnit.floorplate_image}
                />
                <div className="floorplateImgPoints">
                  <div
                    className="floorplatePoint pointUnit pointActive"
                    style={{
                      top: `${pointForUnit.point.point_position_y}%`,
                      left: `${pointForUnit.point.point_position_x}%`,
                      border: `none`,
                      backgroundColor: sketchStyle.unit_color,
                      color: styleProject.search?.[1].color,
                    }}
                  >
                    <span className="floorplate_num">{compareUnitRender}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div id="floorplanPreviewContent" className="">
          <div className="floorplanPreviewTop">
            <div
              className="favourite favouritePreview"
              style={
                !isFavorite
                  ? {
                      backgroundImage: `url(${styleProject.favourites?.["background-image"]})`,
                    }
                  : {
                      backgroundImage: `url(${styleProject.favourites?.["background-image_active"]})`,
                    }
              }
              onClick={(event) => {
                event.stopPropagation();
                toggleFavorite();
              }}
            ></div>
            <div className="floorplanPreviewTitle floorplanTitle">
              <div className="floorplanTitleInner titleFont">
                {compareFloorplan.fp_name.toUpperCase()}
              </div>
            </div>
          </div>

          <div className="floorplanSideInfo">
            <div className="floorplanUnit floorplanUnitPreview">
              <span className="floorplanPriceFeaturePreview smallFeatureTitle">
                Unit
              </span>
              {compareUnitRender}
            </div>

            <div className="floorplanDynamic">
              {compareFloorplan.fp_price !== "0" && displayPrices !== "0" && (
                <div className="floorplanPrice floorplanPricePreview">
                  <span className="floorplanPriceFeaturePreview smallFeatureTitle">
                    From
                  </span>
                  {parseInt(compareFloorplan.fp_price).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}{" "}
                  / mth
                </div>
              )}


                <div
                  className="returnToList closeFormsBgDark"
                  onClick={() => setShowSlider(false)}
                ></div>

            </div>
          </div>

          <div className="floorplanPreviewLeft">
            <div className="floorplanContentRow floorplanContentRow-1">
              <span className="smallFeatureTitle">Beds</span>
              {compareFloorplan.fp_bedrooms}
            </div>

            <div className="floorplanContentRow floorplanContentRow-2">
              <span className="smallFeatureTitle">Baths</span>
              {compareFloorplan.fp_bathrooms}
            </div>

            <div className="floorplanContentRow floorplanContentRow-3">
              <span className="smallFeatureTitle">Area (Sq.Ft.)</span>
              {compareFloorplan.fp_size}
            </div>

            <div className="floorplanContentRow floorplanContentRow-4">
              <span className="smallFeatureTitle">Floor</span>
              {floorUnit ? floorUnit : compareFloorplan.fp_floors_text?.toString()}
            </div>

            <div className="floorplanContentRow floorplanContentRow-5">
              <span className="smallFeatureTitle">Exposure</span>
              {unitOri
                ? unitOri
                : compareFloorplan.fp_orientations?.fp_orientation?.toString()}
            </div>
          </div>

          <div className="floorplanPreviewRight withViews">
            {othersUnits?.length > 0 && othersUnits.find(u => u.unit_number !== compareUnitRender) && (
              <select
              className="dropdown viewDropdown otherUnits"
            //  onChange={(e) => handleUnitChange(e.target.value)}
            >
              <option value="" defaultValue="">
                Other Units
              </option>
              {
                othersUnits?.sort().map(
                  (unit) =>
                    unit.unit_number !== compareUnitRender && (
                      <option key={unit.unit_id} value={JSON.stringify(unit)}>
                        {unit.unit_number}
                      </option>
                    )
                )}
            </select>
          )}

           
          </div>

          <div className="floorplanPreviewBottom noBtnFloorplans">
            <a
              className="btn btn-primary square-button big-btn downloadFloorplan downloadText"
              href={compareFloorplan.fp_pdf}
              target="_blank"
            >
              Download Floorplan
            </a>

            {!externalForm?.url ? (
              <button
                className="btn btn-primary square-button black ml-2 big-btn requestInfoFllorplan innerRequestInfo"
                data-modal-target="request-info"
                data-modal-toggle="request-info"
                style={{
                  backgroundColor: btnReqInfo?.bg,
                  color: btnReqInfo?.color,
                }}
                onClick={openModal}
              >
                Request Information
              </button>
            ) : (
              <IframeLightbox
                iframeSrc={externalForm.url}
                buttonText={externalForm.text}
                stylesBtn={{
                
                }}
                className="btn btn-primary square-button black ml-2 big-btn requestInfoFllorplan innerRequestInfo"
              />
            )}

          
          </div>
        </div>
      </div>

      {showRequestModal && (
        <RequestInfoForm
          closeModal={closeModal}
          flooplan={compareFloorplan}
          contactEmail={contactEmail}
          unitFloor={unitCompareFloorplan}
        />
      )}
    </div>
  );
};

export default CompareFloorplanSlide;
