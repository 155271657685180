import React, { useState } from "react";
import DualRangeSlider from "../../../_Parts/DualRange/index.jsx";
import { isMobileDevice } from "../../../../../plugins/MobileDetect.js";

const orientationMapping = {
  N: "North",
  S: "South",
  E: "East",
  W: "West",
  NE: "Northeast",
  NW: "Northwest",
  SE: "Southeast",
  SW: "Southwest",
}

const OtherFilters = ({
  sliderRef,
  minSize,
  maxSize,
  handleRangeChange,
  minArea,
  maxArea,
  stylesProject,
  setBathFilter,
  bathFilter,
  filterBath,
  setOriFilter,
  oriFilter,
  filterOrientation,
  minFloorSize,
  maxFloorSize,
  handleRangeFloorChange,
  minFloor,
  maxFloor,
}) => {
  // Valores por defecto para los estilos en caso de que stylesProject sea undefined
  const defaultStyles = {
    color: "#000000",
    backgroundColor: "#ffffff"
  };

  const searchColStyles = stylesProject?.searchCol || defaultStyles;

  return (
    <>
        <div
          className="rangeContainer previewFloorplanDropdown"
          id="priceRangeContainer"
        >
          <div className="rangeLabel" 
            style={isMobileDevice() ? {color: searchColStyles.color_active} : {}}
          >AREA (SQ.FT.)</div>
          <div className="calcRange mb-3">
            <DualRangeSlider
              ref={sliderRef}
              min={minSize}
              max={maxSize}
              onMouseUp={({ min, max }) => {
                handleRangeChange({ min, max });
              }}
              minVal={minArea}
              maxVal={maxArea}
              colors={searchColStyles.color_active}
            />
          </div>
        </div>
        <div className="searchCatDiv catDivHalfMobile catDivHalfMobileFirst">
          <div className="rangeLabel"
          style={isMobileDevice() ? {color: searchColStyles.color_active} : {}}>Bathrooms</div>
          <select
            className="dropdown searchDropdown"
            id="bathroomsSelect"
            onChange={(e) => setBathFilter(e.target.value)}
            style={{
              color: searchColStyles.color_active,
              backgroundColor: searchColStyles["background-color_active"],
            }}
            value={bathFilter}
          >
            <option value="">ALL</option>
            {filterBath &&
              filterBath?.map((bath, i) => (
                <option key={i} value={bath}>
                  {Math.round(bath)} BATH
                </option>
              ))}
          </select>
        </div>
        <div className="searchCatDiv catDivHalfMobile catDivHalfMobileSecond">
          <div className="rangeLabel"
          style={isMobileDevice() ? {color: searchColStyles.color_active} : {}}
          >Orientation</div>
          <select
            className="dropdown searchDropdown"
            id="orientationsSelect"
            onChange={(e) => setOriFilter(e.target.value)}
            value={oriFilter}
            style={{
              color: searchColStyles.color_active,
              backgroundColor: searchColStyles["background-color_active"],
            }}
          >
            <option value="">ALL</option>
            {
              filterOrientation?.map((orientation) => (
                <option key={orientation} value={orientation}>
                  {orientationMapping[orientation]}
                </option>
              ))}
          </select>
        </div>
        <div className="rangeContainer searchCatDiv" id="floorRangeContainer">
          <div className="rangeLabel" 
            style={isMobileDevice() ? {color: searchColStyles.color_active} : {}}
          >Floor</div>
          <div className="calcRange">
            <DualRangeSlider
              ref={sliderRef}
              min={minFloorSize}
              max={maxFloorSize}
              onMouseUp={({ min, max }) => {
                handleRangeFloorChange({ min, max });
              }}
              onTouchEnd={({ min, max }) => {
                handleRangeFloorChange({ min, max });
              }}
              minVal={minFloor}
              maxVal={maxFloor}
              colors={searchColStyles.color_active}
            />
          </div>
        </div>
    </>
  );
};

export default OtherFilters;