/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useCallback } from "react";
import IframeLightbox from "../../_Parts/LightBox/iframe";
import { isMobileDevice } from "../../../../plugins/MobileDetect";

const DetailFullFloorplan = ({
  floorplan,
  unitOri,
  masterSketchRef,
  othersUnits,
  unitRender,
  floorUnit,
  isComparation,
  views,
  setView,
  externalForm,
  openModal,
  isActiveComparation,
  btnReqInfo,
  activeComparation,
  setIsComparation,
  pointForUnit,
  getFloorplate,
  floorsFilter,
  setSelectedUnitRender,
  setUnitOri,
  setPointUnit,
  isFullScreen,
  handleClose,
  setUnitFloor,
}) => {
  useEffect(() => {}, [
    floorplan,
    pointForUnit,
    othersUnits,
    unitRender,
    unitOri,
  ]);

  /**
   * Handles the change of the selected unit.
   *
   * This function is memoized using `useCallback` to prevent unnecessary re-renders.
   * It updates the selected unit render and orientation based on the provided unit data.
   *
   * @param {Object} unit - The unit object containing details about the selected unit.
   * @param {string} unit.unit_model_name - The model name of the unit.
   * @param {string} unit.unit_number - The number of the unit.
   * @param {string} unit.unit_orientation - The orientation of the unit.
   * @returns {Promise<void>} - A promise that resolves when the unit change handling is complete.
   */
  const handleUnitChange = useCallback(
    async (unit) => {
      if (masterSketchRef.current) {
        const unitObj = JSON.parse(unit);
        await masterSketchRef.current.filterByFloorplanId(
          floorplan.fp_id,
          unitObj.unit_model_name,
          false,
          floorsFilter
        );

        const point = await getFloorplate(unitObj.unit_id);
        setUnitFloor(unitObj.unit_floor);
        setSelectedUnitRender(unitObj.unit_number);
        setUnitOri(unit.unit_orientation);
        setPointUnit(point);
      }
    },
    [
      masterSketchRef,
      floorplan?.fp_id,
      floorsFilter,
      unitRender,
      unitOri,
      pointForUnit,
    ]
  );

  return (
    <>
      <div className="floorplanPreviewLeft">
        <div className="floorplanContentRow floorplanContentRow-1">
          <span className="smallFeatureTitle">Beds</span>
          {floorplan?.fp_bedrooms}
        </div>

        <div className="floorplanContentRow floorplanContentRow-2">
          <span className="smallFeatureTitle">Baths</span>
          {floorplan?.fp_bathrooms}
        </div>

        <div className="floorplanContentRow floorplanContentRow-3">
          <span className="smallFeatureTitle">Area (Sq.Ft.)</span>
          {floorplan?.fp_size}
        </div>

        <div className="floorplanContentRow floorplanContentRow-4">
          <span className="smallFeatureTitle">Floor</span>
          {floorUnit}
        </div>

        <div className="floorplanContentRow floorplanContentRow-5">
          <span className="smallFeatureTitle">Exposure</span>
          {unitOri
            ? unitOri
            : floorplan?.fp_orientations?.fp_orientation?.toString()}
        </div>
      </div>

      <div className="floorplanPreviewRight withViews">
        {othersUnits?.length > 0 &&
          othersUnits.find((u) => u.unit_number !== unitRender) && (
            <select
              className="dropdown viewDropdown otherUnits"
              onChange={(e) => handleUnitChange(e.target.value)}
            >
              <option value="" defaultValue="">
                Other Units
              </option>
              {othersUnits?.sort().map(
                (unit) =>
                  unit.unit_number !== unitRender && (
                    <option key={unit.unit_id} value={JSON.stringify(unit)}>
                      {unit.unit_number}
                    </option>
                  )
              )}
            </select>
          )}

        {!isComparation && views !== undefined && !isActiveComparation && (
          <div className="btnViews" onClick={() => setView()}>
            View
          </div>
        )}
      </div>

      <div className="floorplanPreviewBottom noBtnFloorplans">
        <a
          className="btn btn-primary square-button big-btn downloadFloorplan downloadText"
          href={floorplan?.fp_pdf}
          target="_blank"
        >
          {!isMobileDevice() ? "Download Floorplan" : ""}
        </a>

        {!externalForm?.url ? (
          <button
            className="btn btn-primary square-button black ml-2 big-btn requestInfoFllorplan innerRequestInfo"
            data-modal-target="request-info"
            data-modal-toggle="request-info"
            style={{
              backgroundColor: btnReqInfo?.bg,
              color: btnReqInfo?.color,
            }}
            onClick={openModal}
          >
            {!isMobileDevice() ? "Request Information" : ""}
          </button>
        ) : (
          <IframeLightbox
            iframeSrc={externalForm?.url}
            buttonText={!isMobileDevice() ? externalForm?.text : ""}
            stylesBtn={{}}
            className="btn btn-primary square-button black ml-2 big-btn requestInfoFllorplan innerRequestInfo"
          />
        )}

        {!isMobileDevice() && (
          <button
            className="btn btn-primary square-button ml-2 w-auto items-center justify-center border-none innersubmit_ws"
            style={{
              display: isComparation || isActiveComparation ? "none" : "block",
            }}
            onClick={() => {
              activeComparation();
              setIsComparation(true);
              if (isFullScreen) {
                handleClose();
              }
            }}
          >
            COMPARE
          </button>
        )}
      </div>
    </>
  );
};

export default DetailFullFloorplan;
