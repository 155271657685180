import React from "react";
import DetailsFloorplan from "./DetailsFloorplan";
import "../_styles/FloorplanItem.css";
import IframeLightbox from "../../_Parts/LightBox/iframe.jsx";
import { isMobileDevice } from "../../../../plugins/MobileDetect.js";

const FloorplanItem = ({
  floorplan,
  isFavorite,
  isRowFloorplan,
  isWorksheet,
  toggleFavorite,
  toogleWorksheets,
  isSelected,
  styleProject,
  isPricing,
  typeColors,
  has360,
  icons,
  onClick,
}) => {

  const typeFpColor = typeColors
    ? typeColors?.find(
        (type) => type.name === floorplan.fp_types?.fp_type_each?.fp_type
      )
    : {};

  return (
    <div
      className={`grid-floorplan p-4 ${isSelected ? "selected" : ""} ${
        isRowFloorplan ? "flex min-h-16 max-h-20" : "min-h-96"
      }`}
      style={{
        backgroundColor:
          styleProject?.fpPreview?.find((item) => item.at === ".fpView")?.[
            "background-color"
          ] || "transparent",
        borderBottom: typeFpColor ? `5px solid ${typeFpColor.color}` : "none",
      }}
      onClick={onClick}
    >
      <div
        className={`grid-floorplan-image ${
          isRowFloorplan ? "row-image flex" : ""
        }`}
      >
        <img
          src={
            floorplan.fp_image_low
              ? floorplan.fp_image_low[0]
              : floorplan.fp_image_med[0]
          }
          alt={floorplan.fp_name}
        />
      </div>
      <div
        className={`grid-floorplan-name inline-flex items-center ${
          isRowFloorplan ? "justify-start w-2/5" : "justify-between	w-full"
        }`}
      >
        <div
          className={`mr-1 msg-tooltip favouriteGrid favourite ${isFavorite ? 'active' : ''} ` }
          style={{
           // !isFavorite
           //   ? {
           //       backgroundImage: `url(${styleProject.favourites["background-image"]})`,
           //     }
           //   : {
           //       backgroundImage: `url(${styleProject.favourites["background-image_active"]})`,
           //     }
          }}
          onClick={(event) => {
            event.stopPropagation();
            toggleFavorite();
          }}
          data-title="Add to Favorites"
        ></div>
        <h3
          className={`ml-1 font-bold w-full floorplanTitleGridInner floorplanTitleInner titleFont titleCardsColors`}
          style={isMobileDevice && isRowFloorplan ? { fontSize: "10px", lineHeight: "16px" } : {}}
        >
          {floorplan.fp_name.toUpperCase()}
        </h3>
        {!isRowFloorplan && (
            <div
              className="worksheet-button ml-2 msg-tooltip"
              style={{ backgroundImage: `url(${icons})` }}
              onClick={(event) => {
                if (isPricing === "0" && has360 === "0") {
                  event.stopPropagation();
                  toogleWorksheets();
                }
              }}
              data-title={isPricing === "0" && has360 === "0" ? "Add to Worksheets" : ""}
            ></div>
          )}

        {!isRowFloorplan &&
          has360 === "1" &&
          floorplan.fp_360.show &&
          floorplan.fp_360.url !== "" && (
            <div
              className="360-button msg-tooltip"
              onClick={(event) => {
                event.stopPropagation();
                //  toogleWorksheets();
              }}
              data-title="View 360"
            >
              <IframeLightbox
                iframeSrc={floorplan.fp_360.url}
                buttonText=""
                stylesBtn={{
                  backgroundImage: `url(${styleProject.show360?.["background-image"]})`,
                }}
                className="tour360-button"
                typeStyle="format"
              />
            </div>
          )}
      </div>

      {isPricing === "1" && !isMobileDevice() && !isRowFloorplan && (
        <div
          className={`grid-floorplan-price floorplanPrice floorplanPriceGrid ${
            isRowFloorplan ? "text-xs" : "w-full"
          }`}
        >
          <p className="mt-0 floorplanPriceInner pb-2">
            <span className="floorplanPriceFeatureGrid smallFeatureTitle">FROM: </span>
            {parseInt(floorplan.fp_price).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}{" "}
            / mth
          </p>
        </div>
      )}
      <DetailsFloorplan
        floorplan={floorplan}
        isRowFloorplan={isRowFloorplan}
        isSelected={isSelected}
      />
    </div>
  );
};

export default FloorplanItem;
