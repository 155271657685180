/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import Loader from "../Loader";
import videoLogo from "../../../../assets/images/video-icon.png";
import "./MasonryGallery.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Isotope from "isotope-layout";

const MasonryGallery = ({ images }) => {
  const containerRef = useRef(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [heights, setHeights] = useState({});
  const [loading, setLoading] = useState(true);
  const [buttonStyle, setButtonStyle] = useState({});

  const imageArray = images?.image || images;

    useEffect(() => {
      let borderColorValue = null;
    
      for (let i = 0; i < document.styleSheets.length; i++) {
        const sheet = document.styleSheets[i];
    
        try {
          const rules = sheet.cssRules || sheet.rules; 
          for (let j = 0; j < rules.length; j++) {
            if (rules[j].selectorText === ".gallery_btn") {
              const cssText = rules[j].style.cssText; 
              const match = cssText.match(/border-color:\s*([^;]+)/); 
    
              if (match) {
                borderColorValue = match[1].trim(); 
                break;
              }
            }
          }
        } catch (error) {
          console.warn(error);
        }
      }
    
      if (borderColorValue) {
        setButtonStyle(borderColorValue); 
      }
    }, []);
    

  useEffect(() => {
    setLoading(true);
    const gridContainer = document.querySelector(".masonry__container");
    const iso = new Isotope(gridContainer, {
      itemSelector: ".masonryItem",
      animationOptions: {
        duration: 500,
      },
      percentPosition: true,
      masonry: {
        columnWidth: ".masonryItem-sizer",
      },
    });

    document.querySelector("#allContainer").style.overflow = "auto";
    if (!imageArray?.length) return;

    setTimeout(() => {
      iso.reloadItems();
      iso.layout();
      setLoading(false);
    }, 500);
  }, [imageArray]);

  /**
   * Opens the lightbox and sets the current index.
   *
   * @param {number} index - The index of the item to display in the lightbox.
   */
  const openLightbox = (index) => {
    setCurrentIndex(index);
    setLightboxOpen(true);
    document.querySelector("#allContainer").style.overflow = "hidden";
  };

  /**
   * Closes the lightbox gallery by adding a "closing" class to the lightbox element,
   * waits for the animation to complete, then sets the lightbox state to closed and
   * pauses all videos within the gallery.
   */
  const closeLightbox = () => {
    const lightbox = document.querySelector(".masonry__gallery");
    if (lightbox) {
      lightbox.classList.add("closing");
      setTimeout(() => {
        setLightboxOpen(false);
        lightbox.classList.remove("closing");
        // Pause all videos
        document
          .querySelectorAll(".masonry__gallery video")
          .forEach((video) => {
            video.pause();
          });
      }, 500); // Tiempo igual a la animación
    }
  };

  useEffect(() => {
    if (!containerRef.current) return;

    /**
     * Updates the heights of masonry items based on their type and width.
     * Sets the loading state to true while updating heights and false after a delay.
     *
     * The height of each item is determined by its type:
     * - s_square: height equals width
     * - b_square: height equals width
     * - v_rectangle: height equals twice the width
     * - h_rectangle: height equals half the width
     * - default: height equals width
     *
     * @returns {void}
     */
    const updateHeights = () => {
      setLoading(true);
      const newHeights = {};
      containerRef.current.querySelectorAll(".masonryItem").forEach((el) => {
        const type = el.dataset.type;
        const width = el.clientWidth;

        const heightMap = {
          s_square: width,
          b_square: width,
          v_rectangle: width * 2,
          h_rectangle: width * 0.5,
        };

        newHeights[el.dataset.position] = heightMap[type] || width;
      });

      setHeights(newHeights);
      setTimeout(() => setLoading(false), 500);
    };

    updateHeights();
    window.addEventListener("resize", updateHeights);
    return () => window.removeEventListener("resize", updateHeights);
  }, [imageArray]);

  const sliderSettings = {
    initialSlide: currentIndex,
    daptiveHeight: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <button className="gallery_btn" style={{border: `3px solid ${buttonStyle}`}}></button>,
  };

  return (
    <>
      {loading && <Loader />}
      <section id="masonryGal">
        <div className="masonry__container" ref={containerRef}>
          <div className="masonryItem-sizer"></div>
          {imageArray?.map((image, index) => (
            <div
              key={index}
              data-position={index}
              data-type={image?.image?.type}
              className={`masonryItem ${image?.image?.type}`}
              style={{
                position: "absolute",
                left: "0%",
                top: "0px",
                height: heights[index] || "300px",
              }}
              onClick={() => openLightbox(index)}
            >
              <div
                className="masonryItemInner"
                style={{
                  backgroundImage: `url(${
                    image?.image?.urlLarge || image?.urlLarge
                  })`,
                }}
              ></div>
              {image?.image?.video !== "" && (
                <div
                  className="masonryVideoIcon"
                  style={{
                    backgroundImage: `url(${videoLogo})`,
                  }}
                ></div>
              )}
            </div>
          ))}
        </div>
        {lightboxOpen && (
          <div className={`masonry__gallery ${lightboxOpen ? "active" : ""}`}>
            <button
              className="masonry__gallery-close gallery_close"
              onClick={closeLightbox}
            >
            </button>
            <Slider
              {...sliderSettings}
              initialSlide={currentIndex}
              className="masonry__gallery__container"
            >
              {imageArray.map((image, index) => (
                <div
                  className={`masonry__gallery-image gallery-image-${image.image?.imageSize}`}
                  key={index}
                >
                  {image?.image?.video !== "" ? (
                    <video
                      src={image.image.video}
                      style={{
                        objectFit: "cover",
                        maxHeight: "100vh",
                        width: "70%",
                      }}
                      controls
                    ></video>
                  ) : (
                    <img
                      src={image?.image?.urlLarge || image?.urlLarge}
                      alt={image?.image?.titleGallery || ""}
                      style={{
                        objectFit: "cover",
                        maxHeight: "90vh",
                        width: "90%",
                      }}
                    />
                  )}
                </div>
              ))}
            </Slider>
          </div>
        )}
      </section>
    </>
  );
};

export default MasonryGallery;
