import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "./style.css";
import { isMobileDevice } from "../../../../plugins/MobileDetect";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Slideshow = ({ images, sectionIndex, subsectionIndex, autoplayInterval = 7000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [userInteracted, setUserInteracted] = useState(false);
  const [imageHeights, setImageHeights] = useState({});
  const [videoHeights, setVideoHeights] = useState({});
  const imagesArray = images?.image || images;
  const videoRefs = useRef([]);
  const imgRefs = useRef([]);
  const [buttonStyle, setButtonStyle] = useState({});



  useEffect(() => {
    let borderColorValue = null;
  
    for (let i = 0; i < document.styleSheets.length; i++) {
      const sheet = document.styleSheets[i];
  
      try {
        const rules = sheet.cssRules || sheet.rules; 
        for (let j = 0; j < rules.length; j++) {
          if (rules[j].selectorText === ".gallery_btn") {
            const cssText = rules[j].style.cssText; 
            const match = cssText.match(/border-color:\s*([^;]+)/); 
  
            if (match) {
              borderColorValue = match[1].trim(); 
              break;
            }
          }
        }
      } catch (error) {
        console.warn(error);
      }
    }
  
    if (borderColorValue) {
      setButtonStyle(borderColorValue); 
    }
  }, []);
  
  
  

  useEffect(() => {
    if (userInteracted) {
      videoRefs.current.forEach((video, index) => {
        if (video) {
          if (index === currentIndex) {
            video.muted = false;
            video
              .play()
              .catch((error) => console.warn("Error playing video:", error));
          } else {
            video.pause();
          }
        }
      });
    }
  }, [currentIndex, userInteracted]);

  const handleUserInteraction = () => {
    if (!userInteracted) {
      setUserInteracted(true);
    }
  };

  const handleImageLoad = (index) => {
    if (imgRefs.current[index]) {
      setImageHeights((prev) => ({
        ...prev,
        [index]: imgRefs.current[index].clientHeight,
      }));
    }
  };

  const handleVideoLoad = (index) => {
    if (videoRefs.current[index]) {
      setVideoHeights((prev) => ({
        ...prev,
        [index]: videoRefs.current[index].clientHeight,
      }));
    }
  };

  const handleVideoClick = () => {
    const video = videoRefs.current[currentIndex];
    if (video) {
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    }
  };

  const sliderSettings = {
    initialSlide: currentIndex,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    appendDots: (dots) => (
      <div>
        <ul className="slick-dots" style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <button className="gallery_btn" style={{border: `3px solid ${buttonStyle}`}}></button>,
    beforeChange: (_, newIndex) => setCurrentIndex(newIndex),
  };

  return (
    <section
      id="slideshow"
      className="slideshow-container relative w-full h-screen"
      onClick={handleUserInteraction}
    >
      <div className="slideshow__gallery">
        <Slider {...sliderSettings} className="slideshow__gallery__container">
          {imagesArray?.map((image, index) => {
            const imageObject = image?.image || image;
            const isMobile = isMobileDevice();
            const hasVideo = Boolean(imageObject?.video);

            return (
              <div key={index}
                className={`slideshow__gallery-image gallery-image-${imageObject?.imageSize}`}
                >
                {imageObject.titleSlideshow && (
                 <div className={`slideshow__gallery-imageInner ${imageObject?.alignImageSlideShow} txtBgColor_${sectionIndex}_${subsectionIndex}`}>
                    <div 
                    className={`slideshow__gallery-imageInnerBlock slideshow__gallery-imageInnerBlockTitle  titleFont  titleColor_${sectionIndex}_${subsectionIndex}`}
                    >{imageObject.titleSlideshow}
                    
                  </div>
                  {imageObject?.descriptionSlideshow && (
                      <div className={`slideshow__gallery-imageInnerBlock  slideshow__gallery-imageInnerBlockText textColor_${sectionIndex}_${subsectionIndex}`}>
                      {imageObject?.descriptionSlideshow}  
                    </div>
                  )}

                 </div>

                )}
                {hasVideo ? (
                  <video
                    ref={(el) => (videoRefs.current[index] = el)}
                    src={imageObject?.video}
                    muted={!userInteracted}
                    className="w-full h-full"
                    onLoadedMetadata={() => handleVideoLoad(index)}
                    onClick={handleVideoClick}
                  ></video>
                ) : (
                  <img
                    ref={(el) => (imgRefs.current[index] = el)}
                    src={imageObject?.urlLarge}
                    alt={imageObject?.titleSlideshow || ""}
                    onLoad={() => handleImageLoad(index)}
                    style={{ display: "block", maxWidth: "100%" }}
                  />
                )}
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default Slideshow;
