/* eslint-disable jsx-a11y/iframe-has-title */
import { createContext, useContext, useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const IframeContext = createContext(null);

export const IframeProvider = ({ children }) => {
  const iframeRef = useRef(null);
  const [projectId, setProjectId] = useState(null);
  const [visible, setVisible] = useState(false);
  const [shouldShow, setShouldShow] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true); 
  const location = useLocation();

  useEffect(() => {
    const match = location.pathname.match(/\/project\/([^/]+)/);
    if (match) {
      setProjectId(match[1]);
    }
  }, [location]);

  useEffect(() => {
    if (!iframeRef.current) return;
    if (projectId && !iframeRef.current.src.includes(projectId)) {
      iframeRef.current.src = `https://projects.zentro.app/project/${projectId}/?only-floorplans=on&loader=off#siteplan`;

      if (firstLoad) {
        setTimeout(() => {
          setShouldShow(true);
          setFirstLoad(false); 
        }, 3000);
      } else {
        setShouldShow(true);
      }
    }
  }, [projectId, firstLoad]);

  useEffect(() => {
    if (!firstLoad) {
      setShouldShow(visible);
    }
  }, [visible, firstLoad]);

  return (
    <IframeContext.Provider value={{ setProjectId, setVisible }}>
      {children}
      <iframe
        ref={iframeRef}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100vh",
          border: "none",
          zIndex: 10,
          opacity: shouldShow ? 1 : 0,
          transition: firstLoad ? "opacity 1s ease-in-out" : "none", 
          pointerEvents: shouldShow ? "auto" : "none",
        }}
      />
    </IframeContext.Provider>
  );
};

export const useIframe = () => useContext(IframeContext);
