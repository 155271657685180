import React, { useEffect } from "react";

const SlideShow = ({section}) => {

    useEffect(() => {
        if(section.images?.length === 1 &&  section.images?.[0].image?.video !== ""){
            console.log(section.images)
        }
    });

    return (
        <div className="h-full w-full">
            <video className="h-full w-full" autoPlay loop controls src={section.images?.[0].image?.video} />
        </div>
    );
};

export default SlideShow;