/* eslint-disable array-callback-return */
/* eslint-disable no-eval */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useParams } from "react-router-dom";
import WorkSheetList from "./WorkSheetList";
import DualRangeSlider from "../../_Parts/DualRange";
import { filterData, SearchType } from "filter-data";
import { saveOrUpdateProject } from "../../../../db";
import "../_styles/FilterBar.css";
import FloatMenu from "../../_Parts/FloatMenu";
import { isMobileDevice, isPortrait } from "../../../../plugins/MobileDetect";
import OtherFilters from "./filters/OtherFilters";

const FilterBar = forwardRef(
  (
    {
      showFavs,
      handleFavsClick,
      floorplans,
      favorites,
      worksheets,
      toggleWorksheets,
      setFloorplans,
      units,
      sketchStyle,
      masterSketchRef,
      sketchSetup,
      updateBuildBoxes,
      resetBoxes,
      isTablet,
      isLandscape,
      setFloorsFilter,
      stylesProject,
      isPricing,
      hideSold,
      isRent,
      isFloorplanPage,
      currentProject,
    },
    ref
  ) => {
    const { projectId } = useParams();
    const [worksheetsList, setWorksheetsList] = useState(false);
    const [unitsAvailables, setUnitsAvailables] = useState([]);
    const [unitsSold, setUnitsSold] = useState([]);
    const [initFloorplans, setInitFloorplans] = useState([]);
    const [filteredFloorplans, setFilteredFloorplans] = useState([]);
    const [typeFilter, setUniq] = useState([]);
    const [oriFilter, setOriFilter] = useState("");
    const [floorFilter, setFloorFilter] = useState("");
    const [bathFilter, setBathFilter] = useState("");
    const [filterType, setFilterType] = useState([]);
    const [filterBath, setFilterBath] = useState([]);
    const [filterOrientation, setFilterOrientation] = useState([]);
    const [filterFloors, setFilterFloor] = useState([]);
    const [floorsFiltered, setFloorsFiltered] = useState([]);
    const [minArea, setMinArea] = useState(0);
    const [minFloor, setMinFloor] = useState(0);
    const [minSize, setMinSize] = useState(0);
    const [maxArea, setMaxArea] = useState(0);
    const [minFloorSize, setMinFloorSize] = useState(0);
    const [maxFloor, setMaxFloor] = useState(0);
    const [maxFloorSize, setMaxFloorSize] = useState(0);
    const [maxSize, setMaxSize] = useState(0);
    const [showFilters, setShowFilters] = useState(false);
    const [initFloors, setInitFloors] = useState([]);
    const [selectedNames, setSelectedNames] = useState("");
    const [rotations, setRotations] = useState({});
    const [filterBarVisible, setFilterBarVisible] = useState(true);
    const [isMasterSketchReady, setIsMasterSketchReady] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [openMobile, setOpenMobile] = useState(false);
    const sliderRef = useRef();

    useEffect(() => {
      const checkInterval = setInterval(() => {
        if (
          masterSketchRef.current &&
          typeof masterSketchRef.current.displayModelAvailability === "function"
        ) {
          setIsMasterSketchReady(true);
          clearInterval(checkInterval);
        }
      }, 100);
      return () => clearInterval(checkInterval);
    }, [masterSketchRef, resetBoxes, isMasterSketchReady]);

    const generateFloorRange = (min, max) => {
      return Array.from({ length: max - min + 1 }, (_, i) => i + min);
    };

    useEffect(() => {
      if (floorplans?.length > 0) {
        let searchConditions = [
          {
            key: "fp_name",
            value: selectedNames,
            type: selectedNames !== "" ? SearchType.LK : SearchType.NE,
          },
          {
            key: "fp_size",
            value: minArea,
            type: SearchType.GTE,
          },
          {
            key: "fp_size",
            value: maxArea,
            type: SearchType.LTE,
          },
          {
            key: "fp_bathrooms",
            value: bathFilter,
            type: bathFilter !== "" ? SearchType.LK : SearchType.NE,
          },
          {
            key: "fp_orientations.fp_orientation",
            value: oriFilter,
            type: oriFilter !== "" ? SearchType.EQ : SearchType.NLK,
          },
        ];

        const filtered = filterData(initFloorplans, searchConditions);

        let logicType =
          filterType.length > 0
            ? filtered.filter((item) =>
                filterType.includes(item.fp_types.fp_type_each.fp_type)
              )
            : filtered;

        setFilteredFloorplans(logicType);
        setFloorplans(logicType);
        refreshBuildBoxes(logicType);
      }
    }, [
      filterType,
      filterBath,
      minArea,
      maxArea,
      minFloor,
      maxFloor,
      initFloorplans,
      oriFilter,
      bathFilter,
      selectedNames,
      openSearch,
      openMobile,
    ]);

    useEffect(() => {
    }, [
      filterType,
      filterBath,
      minArea,
      maxArea,
      minFloor,
      maxFloor,
      oriFilter,
      floorsFiltered,
      floorFilter,
      filteredFloorplans,
      openMobile,
    ]);

    /**
     * Toggles the visibility of the filter bar.
     *
     * This function switches the state of `filterBarVisible` between `true` and `false`.
     */
    const toggleFilterBar = () => {
      setFilterBarVisible(!filterBarVisible);
    };

    /**
     * Handles the change event for a select input element and updates the selected names.
     *
     * @param {Event} e - The change event triggered by the select input element.
     */
    const handleNameChange = (e) => {
      setSelectedNames(e);
      setRotations("");
    };

    /**
     * Toggles the rotation state of a given item.
     *
     * @param {string} itemName - The name of the item to toggle rotation for.
     */
    const handleRotateClick = (itemName) => {
      setRotations(itemName === rotations ? "" : itemName);
    };

    /**
     * Handles the change event for the bedroom filter.
     *
     * @param {Event} e - The event object from the change event.
     * @returns {Promise<void>} A promise that resolves when the filter type is set.
     */
    const handleBedroomChange = (e) => {
      setFilterType((prev) => {
        if (prev.includes(e)) {
          return prev.filter((item) => item !== e);
        } else {
          return [...prev, e];
        }
      });
    };

    /**
     * Handles the change in range values for the filter.
     *
     * @param {Object} range - The range object containing min and max values.
     * @param {number} range.min - The minimum value of the range.
     * @param {number} range.max - The maximum value of the range.
     */
    const handleRangeChange = ({ min, max }) => {
      setMinArea(min);
      setMaxArea(max);
    };

    /**
     * Handles the change in floor range selection.
     *
     * @param {Object} param - The floor range object.
     * @param {number} param.min - The minimum floor value.
     * @param {number} param.max - The maximum floor value.
     */
    const handleRangeFloorChange = ({ min, max }) => {
      setMinFloor(min);
      setMaxFloor(max);

      const range = generateFloorRange(min, max);
      setFloorsFilter(range);
      setFloorsFiltered(range);

      saveOrUpdateProject({
        id: currentProject.id !== undefined ? currentProject.id : currentProject.id_project,
        range_floors_filter: range,
      });
    };

    useEffect(() => {
      const range = generateFloorRange(minFloor, maxFloor);
      const filteredByFloor = initFloorplans.filter((fp) =>
        fp.fp_floors?.fp_floor?.some((floor) => range.includes(floor))
      );

      setFilteredFloorplans(filteredByFloor);
      setFloorplans(filteredByFloor);
    }, [minFloor, maxFloor, initFloorplans]);

    /**
     * The `handleReset` function resets various state variables and updates floor units based on
     * availability.
     */
    const handleReset = async () => {
      if (sliderRef.current) sliderRef.current.resetSlider();

      const resetValues = {
        minArea: minSize,
        minSize,
        minFloorSize,
        maxFloorSize,
        maxArea: maxSize,
        maxSize,
        minFloor: minFloorSize,
        maxFloor: maxFloorSize,
        floorsFilter: initFloors,
        floorsFiltered: initFloors,
        filterType: "",
        oriFilter: "",
        bathFilter: "",
        selectedNames: "",
        floorplans: initFloorplans,
        filteredFloorplans: initFloorplans,
      };

      Object.entries(resetValues).forEach(([key, value]) => {
        eval(`set${key.charAt(0).toUpperCase() + key.slice(1)}(value)`);
      });
      saveOrUpdateProject({
        id: currentProject.id !== undefined ? currentProject.id : currentProject.id_project,
        range_floors_filter: initFloors,
      });

      setRotations("");

      setTimeout(async () => {
        const availableUnits = units
          .filter((unit) => unit.unit_status === "available")
          .map((u) => u.unit_model_name);
        const soldUnits =
          hideSold === "1"
            ? []
            : units
                .filter((unit) => unit.unit_status !== "available")
                .map((u) => u.unit_model_name);
        await updateBuildBoxes(availableUnits, soldUnits, sketchStyle);
      }, 200);
    };

    /**
     * Refreshes the build boxes based on the provided filter.
     *
     * @param {Array} filter - The filter criteria to apply.
     * @returns {Promise<void>} A promise that resolves when the build boxes have been updated.
     *
     * @async
     */
    const refreshBuildBoxes = async (filter) => {
      if (filter.length === 0) {
        setShowFilters(false);
        if (isMasterSketchReady) {
          updateBuildBoxes([], [], sketchStyle);
        }
        return;
      }

      /**
       * Filters the units based on the provided criteria.
       *
       * @param {boolean} isAvailable - Indicates whether to filter for available units.
       * @returns {string[]} - An array of unit model names that match the filter criteria.
       */
      const filterUnits = (isAvailable) =>
        units
          .filter((unit) =>
            filter.some((fp) => {
              const matchesFloorPlan = fp.fp_id === unit.fp_id;
              const matchesStatus = isAvailable
                ? unit.unit_status === "available"
                : unit.unit_status !== "available";
              const matchesOrientation =
                !oriFilter || unit.unit_orientation === oriFilter;
              const matchesFloor =
                !floorsFiltered || floorsFiltered.includes(unit.unit_floor);
              return (
                matchesFloorPlan &&
                matchesStatus &&
                matchesOrientation &&
                matchesFloor
              );
            })
          )
          .map((unit) => unit.unit_model_name);
      const filteredUnitsAvailable = filterUnits(true);
      const filteredUnitsSold = filterUnits(false);

      setUnitsAvailables(filteredUnitsAvailable);
      setUnitsSold(filteredUnitsSold);

      await updateBuildBoxes(
        filteredUnitsAvailable,
        hideSold === "1" ? [] : filteredUnitsSold,
        sketchStyle,
        floorFilter
      );

      if (isMasterSketchReady) {
        const orientationCam =
          oriFilter ||
          units.find((unit) => unit.fp_id === filter[0].fp_id).unit_orientation;
        await masterSketchRef.current.sketchRotateCamera(
          sketchSetup.building_name,
          sketchSetup.orientation_camera[sketchSetup.building_name].orientation[
            orientationCam
          ]
        );
      }
    };

    useEffect(() => {
      const handleClick = async (event) => {
        const closeButton = event.target.closest("#close-floorplan");
        if (closeButton && filteredFloorplans.length < initFloorplans.length) {
          await updateBuildBoxes(
            unitsAvailables,
            hideSold === "1" ? [] : unitsSold,
            sketchStyle
          );
        }
      };

      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    }, [unitsAvailables, unitsSold, sketchStyle, oriFilter]);

    useEffect(() => {
      if (floorplans.length > 0 && initFloorplans.length === 0) {
        const uniq = Array.from(
          new Set(
            floorplans
              .map((fp) => fp.fp_types?.fp_type_each?.fp_type)
              .filter(
                (fp_type) =>
                  fp_type !== undefined && fp_type !== null && fp_type !== ""
              )
          )
        );

        const baths = Array.from(
          new Set(floorplans.map((fp) => fp.fp_bathrooms))
        );
        const orientation = Array.from(
          new Set(floorplans.flatMap((fp) => fp.fp_orientations.fp_orientation))
        );
        const floors = Array.from(
          new Set(floorplans.flatMap((fp) => fp.fp_floors.fp_floor))
        );

        if (filterFloors.length === 0) {
          setFloorsFilter(floors);
          setInitFloors(floors);
          setFloorsFiltered(floors);
        }

        setFilterFloor(floors);
        setFilterOrientation(orientation);
        setFilterBath(baths);
        setInitFloorplans(floorplans);
        setUniq(uniq);

        if (floorplans.length > 0) {
          const sizes = floorplans.map((fp) => fp.fp_size);
          const maxFloorplanSize = Math.max(...sizes);
          const minFloorplanSize = Math.min(...sizes);

          setMaxSize(maxFloorplanSize);
          setMaxArea(maxFloorplanSize);
          setMinArea(minFloorplanSize);
          setMinSize(minFloorplanSize - 10);

          const maxFloorSize = Math.max(...floors);
          const minFloorSize = Math.min(...floors);

          setMaxFloorSize(maxFloorSize);
          setMaxFloor(maxFloorSize);
          setMinFloor(minFloorSize);
          setMinFloorSize(minFloorSize);
        }
      }
    }, [floorplans, initFloorplans, filterFloors]);

    useImperativeHandle(ref, () => ({
      handleReset,
      oriFilter,
    }));
 
    return (
      <>
        <FloatMenu
          favorites={favorites}
          worksheets={worksheets}
          isRent={isRent}
          handleFavsClick={handleFavsClick}
          isFloorplanPage={isFloorplanPage}
          setOpenSearch={setOpenSearch}
          setOpenMobile={setOpenMobile}
          openSearch={isMobileDevice() ? true : openSearch}
          openMobile={openMobile}
          toggleWorksheets={toggleWorksheets}
          showWorksheets={currentProject?.data?.show_worksheet_btn}
        />

        {!isMobileDevice() ? (
          <div
            id="search"
            className={!openSearch ? "visible" : ""}
            style={{
              ...(!isRent ? { right: "165px" } : { right: "105px" }),
              ...(currentProject?.data?.show_worksheet_btn === "0" ? { right: "105px", width: "calc(49% - 105px)" } : {}),
            }}
          >
            <div id="searchInner">
              <div
                className="searchCol"
                id="searchColBuilding"
                style={{
                  display: "none",
                  ...(!openSearch ? { opacity: 1 } : { opacity: 0 }),
                }}
              >
                <div className="searchColTitle">
                  <div className="searchColTitleInner contentFont">
                    Building
                  </div>
                </div>
                <div className="searchColContent" id="searchForBuilding"></div>
                <div id="searchForBuildMobile">
                  <select
                    className="dropdown searchDropdown"
                    id="searchForBuildDropdown"
                    // onchange="changeBuilding(this.value)"
                  ></select>
                </div>
              </div>

              <div
                className={`searchCol ${
                  rotations === "byTape" ? "active" : ""
                }`}
                id="searchColType"
                onClick={() => handleRotateClick("byTape")}
                style={{
                  display: "block",
                  ...(!openSearch ? { opacity: 1 } : { opacity: 0 }),
                }}
              >
                <div className="searchColTitle">
                  <div className="searchColTitleInner contentFont">Type</div>
                </div>
                <div
                  className="searchColContent"
                  id="searchForType"
                  style={
                    rotations === "byTape"
                      ? { display: "block", opacity: 1 }
                      : { display: "none", opacity: 0 }
                  }
                >
                <div>
                  {typeFilter &&
                    typeFilter
                      .sort((a, b) => a.localeCompare(b)) 
                      .map((bedroom) => {
                        const typeColor = currentProject?.data?.types_colors
                          ? currentProject?.data?.types_colors?.find((type) => type.name === bedroom)
                          : [];

                        return (
                          <div key={bedroom} className="searchCatDiv searchCol2">
                            <label className="customCheckbox">
                              <input
                                type="checkbox"
                                className="checkbox filterCheckbox"
                                data-name={bedroom}
                                checked={filterType.includes(bedroom)}
                                onChange={() => handleBedroomChange(bedroom)}
                              />
                              <span
                                className={`checkboxItem slug_${bedroom}`}
                                style={{
                                  backgroundColor: filterType.includes(bedroom)
                                    ? typeColor?.color || "white"
                                    : "transparent",
                                }}
                              ></span>
                              <span className="checkboxContent">{bedroom}</span>
                            </label>
                          </div>
                        );
                      })}
                </div>
                </div>
              </div>

              <div
                className={`searchCol ${
                  rotations === "byMore" ? "active" : ""
                }`}
                id="searchColMore"
                style={{
                  display: "block",
                  ...(!openSearch ? { opacity: 1 } : { opacity: 0 }),
                }}
              >
                <div
                  className="searchColTitle invMobile"
                  onClick={() => handleRotateClick("byMore")}
                >
                  <div className="searchColTitleInner contentFont">
                    More Filters
                  </div>
                </div>
                <div
                  className="searchColContent"
                  id="searchForMore"
                  style={
                    rotations === "byMore"
                      ? { display: "block", opacity: 1 }
                      : { display: "none", opacity: 0 }
                  }
                >
                  <OtherFilters
                    rotations={rotations}
                    openSearch={openSearch}
                    handleRotateClick={handleRotateClick}
                    handleRangeChange={handleRangeChange}
                    handleRangeFloorChange={handleRangeFloorChange}
                    minFloor={minFloor}
                    maxFloor={maxFloor}
                    sliderRef={sliderRef}
                    minArea={minArea}
                    maxArea={maxArea}
                    minSize={minSize}
                    maxSize={maxSize}
                    minFloorSize={minFloorSize}
                    maxFloorSize={maxFloorSize}
                    stylesProject={stylesProject}
                    setBathFilter={setBathFilter}
                    bathFilter={bathFilter}
                    filterBath={filterBath}
                    setOriFilter={setOriFilter}
                    oriFilter={oriFilter}
                    filterOrientation={filterOrientation}
                  />
                </div>
              </div>
              <div
                className={`searchCol ${
                  rotations === "byName" ? "active" : ""
                }`}
                style={{
                  display: "block",
                  ...(!openSearch ? { opacity: 1 } : { opacity: 0 }),
                }}
              >
                <div
                  className="searchColTitle"
                  onClick={() => handleRotateClick("byName")}
                >
                  <div className="searchColTitleInner contentFont">By Name</div>
                </div>
                <div
                  className="searchColContent"
                  id="searchForName"
                  style={{
                    ...(rotations === "byName"
                      ? { display: "block", opacity: 1 }
                      : { display: "none", opacity: 0 }),
                    maxHeight: "498px",
                  }}
                >
                  {initFloorplans &&
                    initFloorplans.map((fp) => (
                      <div
                        key={fp.fp_id}
                        className="searchCatDiv"
                        style={{ color: stylesProject.searchCol?.color_active }}
                        onClick={() => handleNameChange(fp.fp_name)}
                      >
                        {fp.fp_name}
                      </div>
                    ))}
                </div>
              </div>

              <div
                className="searchCol"
                id="resetSearch"
                style={{
                  display: "block",
                  ...(!openSearch ? { opacity: 1 } : { opacity: 0 }),
                }}
              >
                <div
                  className="searchColTitle"
                  onClick={() => {
                    handleReset();
                  }}
                >
                  <div className="searchColTitleInner contentFont">Reset</div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            id="search"
            className={`${openMobile ? "visible" : ""}`}
            style={{
              left: "0px",
              width: "100%",
              height: openMobile ? "100svh" : 0,
            }}
          >
            <div
              className={`searchColContent absolute w-4/5`}
              style={{
                display: openMobile ? "block" : "none",
                opacity: openMobile ? 1 : 0,
                width: "100%",
              }}
              id="searchForMore"
            >

              <div className="searchColTitle">
                  <div className="searchColTitleInner contentFont"
                  style={{
                    color: stylesProject.searchCol?.color_active,
                    backgroundColor:
                      stylesProject.searchCol?.["background-color_active"],
                  }}
                  >Type</div>
                </div>
                <div
                  id="searchForType"
                >
                  {typeFilter &&
                    typeFilter
                      .sort((a, b) => a.localeCompare(b)) 
                      .map((bedroom) => {
                        const typeColor = currentProject?.data?.types_colors
                          ? currentProject?.data?.types_colors?.find((type) => type.name === bedroom)
                          : [];

                        return (
                          <div key={bedroom} className="searchCatDiv searchCol2">
                            <label className="customCheckbox">
                              <input
                                type="checkbox"
                                className="checkbox filterCheckbox"
                                data-name={bedroom}
                                checked={filterType.includes(bedroom)}
                                onChange={() => handleBedroomChange(bedroom)}
                              />
                              <span
                                className={`checkboxItem slug_${bedroom}`}
                                style={{
                                  backgroundColor: filterType.includes(bedroom)
                                    ? typeColor?.color || "white"
                                    : "transparent",
                                }}
                              ></span>
                              <span className="checkboxContent">{bedroom}</span>
                            </label>
                          </div>
                        );
                      })}
                </div>

              <OtherFilters
                rotations={rotations}
                openSearch={openSearch}
                handleRotateClick={handleRotateClick}
                handleRangeChange={handleRangeChange}
                handleRangeFloorChange={handleRangeFloorChange}
                minFloor={minFloor}
                maxFloor={maxFloor}
                sliderRef={sliderRef}
                minArea={minArea}
                maxArea={maxArea}
                minSize={minSize}
                maxSize={maxSize}
                minFloorSize={minFloorSize}
                maxFloorSize={maxFloorSize}
                stylesProject={stylesProject}
                setBathFilter={setBathFilter}
                bathFilter={bathFilter}
                filterBath={filterBath}
                setOriFilter={setOriFilter}
                oriFilter={oriFilter}
                filterOrientation={filterOrientation}
              />

              <div className="searchColTitle">
                <div className="searchColTitleInner subtitleFont" style={{
                      color: stylesProject.searchCol?.color_active
                    }}>By Name</div>

                <div id="searchForNameMobile">
                  <select
                    className="dropdown searchDropdown"
                    id="searchForNameDropdown"
                    defaultValue=""
                    onChange={(el) => handleNameChange(el.target.value)}
                    style={{
                      color: stylesProject.searchCol?.color_active,
                      backgroundColor:
                        stylesProject.searchCol?.["background-color_active"],
                    }}
                  >
                    <option value="" defaultValue>
                      Select a Floor Plan
                    </option>
                    {initFloorplans?.map((fp) => (
                        <option key={fp.fp_id} value={fp.fp_name} selected={selectedNames === fp.fp_name}>
                          {fp.fp_name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div id="searchBtnsMobile">
              <div
                id="searchBtnMobileReset"
                className="searchBtnMobile"
                onClick={() => {
                  handleReset();
                  setOpenMobile(false);
                }}
              >
                Reset
              </div>
              <div
                id="searchBtnMobileApply"
                className="searchBtnMobile"
                onClick={() => setOpenMobile(false)}
              >
                Apply Filters
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);

export default FilterBar;
