import React, {useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../header";
import Menu from "../Templates/_Parts/Menu";
import Logo from "../Templates/_Parts/Logo";
import ContactHeader from "../Templates/_Parts/ContactHeader";
import PageContent from "../PageContent";
import Subpage from "../SinglePage";
import { getStyleObjectFromString } from "../../plugins/FormatStyle";
import slugify from "react-slugify";
import FloatMenu from "../Templates/_Parts/FloatMenu";
import "./Project.css";
const Project = ({ project }) => {
  const [floorplanPage, setFloorplanPage] = useState(false);

  useEffect(() => {

    if (!project) return;
    let findFloorPage = project?.content?.section.find((section) => section.subsection.find((sub) => sub.template === "floorplans"));
    setFloorplanPage(findFloorPage);

  }, [project]);

  if (!project) return null;

  return (
    <div>
      <Header fonts={project.fonts?.font} />
      <Menu
        styles={project.styles}
        bgMenu={project.content?.bg_image_menu}
        content={project.content}
        logoMenu={project.project_data?.logo_main_menu}
      />

        <FloatMenu 
          favorites={project?.favorites}
          worksheets={project?.worksheets}
          isRent={parseInt(project?.project_data?.saleOrRent)}
          isFloorplanPage={false}
          floorplanPage={floorplanPage}
          projectType={project?.project_data?.project_type}
          showWorksheets={project?.project_data?.show_worksheet_btn}
      />

        
    
      <Logo img={project.content?.logo} />
      <ContactHeader email={project.content?.contact_mail} />
      <div
        id="allContainer"
        style={getStyleObjectFromString(project.styles.allContent)}
      >
        <Routes>
          {project.content?.section.map((page) => {
            const pageSlug = slugify(page.title);
            return (
              <Route
                key={pageSlug}
                path={pageSlug}
                element={
                  <PageContent
                    page={page}
                    colors={project?.styles}
                    projectType={project?.project_data?.project_type}
                    sections={project?.content?.section}
                    mapStyle={project?.map || null}
                  />
                }
              />
            );
          })}
          <Route
            path=":sectionSlug/:subsectionSlug"
            element={
              <Subpage
                sectionContent={project.content?.section}
                colors={project.styles}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default Project;
