/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "../_styles/PointDetail.css";

const DetailsPoint = ({ point, colors, isProject }) => {
  return (
    <div className="infowindow">
      <div className="fullTitle subtitle coffee titleFont infowindowTitle">
        <h3>{point.name}</h3>
      </div>
      <div className="regular infoAdress">{point.address}</div>

      {!isProject && (
        <>
          <div className="routes infowindow ">
            <div className="infowindowTimes">
              <div className="infowindowTime" id="infowindowTime-0">
                {point.walk} min
              </div>

              <div className="infowindowTime" id="infowindowTime-1">
                {point.bike} min
              </div>

              <div className="infowindowTime" id="infowindowTime-2">
                {point.car} min
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DetailsPoint;
