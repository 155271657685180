const DB_NAME = 'zentro-projects';
const DB_VERSION = 1; // Asegúrate de usar una versión mayor para activar el onupgradeneeded
const STORE_NAME = 'projects';

// Abre la base de datos
const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;

      // Si no existe el objectStore 'projects', lo creamos
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        const store = db.createObjectStore(STORE_NAME, { keyPath: 'id' });
        store.createIndex('slug', 'slug', { unique: true }); // Creamos el índice 'slug'
      } else {
        // Si el objectStore ya existe, solo creamos el índice si no existe
        const store = event.target.transaction.objectStore(STORE_NAME);
        if (!store.indexNames.contains('slug')) {
          store.createIndex('slug', 'slug', { unique: true });
        }
      }
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

// Guarda o actualiza un proyecto
export const saveOrUpdateProject = async (project) => {
  try {
    const db = await openDB();
    const transaction = db.transaction(STORE_NAME, 'readwrite');
    const store = transaction.objectStore(STORE_NAME);

    // Obtener el proyecto existente por ID
    const existingProject = await new Promise((resolve, reject) => {
      const request = store.get(project?.id);
      
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });

    if (existingProject) {
      // Si existe, fusionamos los datos del proyecto existente con los nuevos
      const updatedProject = { ...existingProject, ...project };
      const request = store.put(updatedProject);

      return new Promise((resolve, reject) => {
        request.onsuccess = () => resolve();
        request.onerror = () => reject(request.error);
      });
    } else {
      // Si no existe, lo agregamos
      const request = store.add(project);

      return new Promise((resolve, reject) => {
        request.onsuccess = () => resolve();
        request.onerror = () => reject(request.error);
      });
    }
  } catch (error) {
    console.error('Error saving or updating project:', error);
    throw error;
  }
};

// Obtiene todos los proyectos de IndexedDB
export const getProjects = async () => {
  const db = await openDB();
  const transaction = db.transaction(STORE_NAME, 'readonly');
  const store = transaction.objectStore(STORE_NAME);
  const projects = [];

  return new Promise((resolve, reject) => {
    const request = store.openCursor();

    request.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        projects.push(cursor.value);
        cursor.continue();
      } else {
        resolve(projects);
      }
    };

    request.onerror = () => reject(request.error);
  });
};

// Obtiene un proyecto por ID
export const getProjectById = async (id) => {
  const db = await openDB();
  const transaction = db.transaction(STORE_NAME, 'readonly');
  const store = transaction.objectStore(STORE_NAME);

  return new Promise((resolve, reject) => {
    const request = store.get(id);
    request.onsuccess = () => resolve(request.result);
    request.onerror = () => {
      console.error('Error while getting project by ID:', request.error);
      reject(request.error);
    };
  });
};

// Obtiene un proyecto por slug (usando el índice)
export const getProjectBySlug = async (slug) => {
  const db = await openDB();
  const transaction = db.transaction(STORE_NAME, 'readonly');
  const store = transaction.objectStore(STORE_NAME);

  return new Promise((resolve, reject) => {
    const index = store.index('slug');  // Usamos el índice 'slug'
    const request = index.get(slug);    // Realizamos la consulta por 'slug'

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => {
      console.error('Error while getting project by Slug:', request.error);
      reject(request.error);
    };
  });
};
