/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import PanoramaView from "./PanoramaView";
import RequestInfoForm from "./RequestInfoForm";
import { isMobile } from "react-device-detect";
import IframeLightbox from "../../_Parts/LightBox/iframe";
import FullScreen from "./FullScreen";
import "../_styles/SelectedFloorplanSlide.css";
import DetailFullFloorplan from "./DetailFullFloorplan";
import { isMobileDevice } from "../../../../plugins/MobileDetect";
const SelectedFloorplanSlide = ({
  floorplan,
  onClose,
  unitRender,
  setSelectedUnitRender,
  isFavorite,
  floorUnit,
  pointForUnit,
  getFloorplate,
  setPointUnit,
  styleProject,
  toggleFavorite,
  units,
  masterSketchRef,
  views,
  activeComparation,
  filterBarRef,
  contactEmail,
  floorsFilter,
  externalForm,
  sketchStyle,
  displayPrices,
  isActiveComparation,
  isLeftExpanded,
  setIsLeftExpanded,
  setUnitFloor

}) => {
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [othersUnits, setOthersUnits] = useState([]);
  const [unitOri, setUnitOri] = useState("");
  const [selectedView, setSelectedView] = useState(null);
  const [showPanorama, setShowPanorama] = useState(false);
  const [isComparation, setIsComparation] = useState(false);
  const [btnReqInfo, setBtnReqInfo] = useState({});
  const [showSlider, setShowSlider] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const openModal = () => setShowRequestModal(true);
  const closeModal = () => setShowRequestModal(false);

  /**
   * Filters and sorts units based on the given floorplan ID and their availability status.
   *
   * @param {number} floorplanId - The ID of the floorplan to filter units by.
   * @returns {void} This function does not return a value. It sets the state with the filtered and sorted units.
   */
  const getOthersUnits = (floorplanId) => {
    let othersUnits = units?.filter(
      (unit) =>
        unit.fp_id === floorplanId &&
        unit.unit_status === "available" &&
        (filterBarRef.current.oriFilter !== ""
          ? unit.unit_orientation === filterBarRef.current.oriFilter
          : true) &&
        (!floorsFilter || floorsFilter.includes(unit.unit_floor))
    );

    let orderUnits = othersUnits?.sort((a, b) =>
      parseInt(a.unit_name) > parseInt(b.unit_name) ? 1 : -1
    );
    setOthersUnits(orderUnits);
  };



  /**
   * Sets the selected view based on the current floor unit and displays the panorama.
   *
   * This function searches through the available views to find one that includes the specified floor unit.
   * Once found, it sets the selected view and enables the panorama display.
   *
   * @function
   */
  const setView = () => {
    const view = views.find((view) => view.floors.includes(floorUnit));
    setSelectedView(view);
    setShowPanorama(true);
  };

  useEffect(() => {
    setShowSlider(true);
    isLeftExpanded && setIsLeftExpanded(false);
    setBtnReqInfo({
      bg: styleProject?.requestInfo?.find(
        (item) => item["at"] === ".innerRequestInfo"
      )?.["background-color"],
      color: styleProject?.requestInfo?.find(
        (item) => item["at"] === ".innerRequestInfo"
      )?.color,
    });
    getOthersUnits(floorplan?.fp_id);
  }, [floorplan, unitOri, pointForUnit, styleProject]);

  return (
    <div
      className={`selected-floorplan-slide ${showSlider ? "show" : "hide"}`}
      style={{
        boxShadow: isComparation
          ? "-9px 0px 13px -3px rgb(233 233 233 / 75%)"
          : "",
      }}
    >
      <button
        className="close-btn closeView"
        id="close-floorplan"
        onClick={onClose}
      ></button>

      <div
        id="floorplanImagePreview"
        className="floorplanImageView floorplan-img"
      >
        <img
          src={floorplan?.fp_image_med[0]}
          alt={floorplan?.fp_name}
          className="full-size-image"
        />
        {!isComparation && !isActiveComparation && !isMobileDevice() && (
          <button onClick={() => setIsFullScreen(true)} className="fpFull"></button>
        )}
      </div>

      <div id="floorplanPreviewContainer">
        {pointForUnit && pointForUnit.point && (
          <div id="floorplanPreviewFloorplate" className="floorplanFloorplate">
            <div className="floorplate previewFloorplate">
              <div className="floorplateInner">
                <img
                  className="floorplateImg"
                  src={pointForUnit.floorplate_image}
                />
                <div className="floorplateImgPoints">
                  <div
                    className="floorplatePoint pointUnit pointActive"
                    style={{
                      top: `${pointForUnit.point?.point_position_y}%`,
                      left: `${pointForUnit.point?.point_position_x}%`,
                      border: `none`,
                      backgroundColor: sketchStyle.unit_color,
                      color: styleProject.search?.[1].color,
                    }}
                  >
                    <span className="floorplate_num">{unitRender}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div id="floorplanPreviewContent" className="">
          <div className="floorplanPreviewTop">
            <div
              className="favourite favouritePreview"
              style={
                !isFavorite
                  ? {
                      backgroundImage: `url(${styleProject?.favourites?.["background-image"]})`,
                    }
                  : {
                      backgroundImage: `url(${styleProject?.favourites?.["background-image_active"]})`,
                    }
              }
              onClick={(event) => {
                event.stopPropagation();
                toggleFavorite();
              }}
            ></div>
            <div className="floorplanPreviewTitle floorplanTitle">
              <div className="floorplanTitleInner titleFont">
                {floorplan?.fp_name?.toUpperCase()}
              </div>
            </div>
          </div>

          <div className="floorplanSideInfo">
            <div className="floorplanUnit floorplanUnitPreview">
              <span className="floorplanPriceFeaturePreview smallFeatureTitle">
                Unit
              </span>
              {unitRender}
            </div>

            <div className="floorplanDynamic">
              {floorplan?.fp_price !== "0" && displayPrices !== "0" && (
                <div className="floorplanPrice floorplanPricePreview">
                  <span className="floorplanPriceFeaturePreview smallFeatureTitle">
                    From
                  </span>
                  {parseInt(floorplan?.fp_price).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}{" "}
                  / mth
                </div>
              )}

              {!isComparation && !isActiveComparation ? (
                <>
                  {floorplan?.fp_360?.show && floorplan?.fp_360?.url !== "" && (
                    <div
                      className="360-button msg-tooltip"
                      style={{height: "40px", width: "40px"}}
                      onClick={(event) => {
                        event.stopPropagation();
                        // toogleWorksheets();
                      }}
                      data-title="View 360"
                    >
                      <IframeLightbox
                        iframeSrc={floorplan.fp_360.url}
                        buttonText=""
                        stylesBtn={{
                          backgroundImage: `url(${styleProject.show360?.["background-image"]})`,
                        }}
                        className="tour360-button relative"
                        typeStyle="format"
                      />
                    </div>
                  )}
                </>
              ) : (
                <div
                  className="returnToList closeFormsBgDark"
                  onClick={() => setShowSlider(false)}
                ></div>
              )}
            </div>
          </div>

          <DetailFullFloorplan 
            isComparation={isComparation}
            views={views}
            unitRender={unitRender}
            floorUnit={floorUnit}
            isActiveComparation={isActiveComparation}
            setView={setView}
            floorplan={floorplan}
            externalForm={externalForm}
            openModal={openModal}
            othersUnits={othersUnits}
            btnReqInfo={btnReqInfo}
            pointForUnit={pointForUnit}
            activeComparation={activeComparation}
            setIsComparation={setIsComparation}
            getFloorplate={getFloorplate}
            floorsFilter={floorsFilter}
            masterSketchRef={masterSketchRef}
            setSelectedUnitRender={setSelectedUnitRender}
            setPointUnit={setPointUnit}
            setUnitOri={setUnitOri}
            setUnitFloor={setUnitFloor}
          />
        </div>
      </div>

      {selectedView && showPanorama && (
        <PanoramaView
          data={selectedView}
          onClosePanorama={() => {
            setShowPanorama(false);
            document.querySelector("#burguer").style.display = "block";
          }}
          point=              {unitOri
            ? unitOri
            : floorplan.fp_orientations?.fp_orientation?.toString()}
        />
      )}
      {showRequestModal && (
        <RequestInfoForm
          closeModal={closeModal}
          floorplan={floorplan}
          contactEmail={contactEmail}
          unitFloor={floorUnit}
        />
      )}

      {isFullScreen && (
        <FullScreen
            floorplan={floorplan}
            sketchStyle={sketchStyle}
            setIsFullScreen={setIsFullScreen}
            isFullScreen={isFullScreen}
            pointForUnit={pointForUnit}
            displayPrices={displayPrices}
            units={units}
            views={views}
            setView={setView}
            externalForm={externalForm}
            openModal={openModal}
            isActiveComparation={isActiveComparation}
            btnReqInfo={btnReqInfo}
            isComparation={isComparation}
            activeComparation={activeComparation}
            setIsComparation={setIsComparation}
            getFloorplate={getFloorplate}
            floorsFilter={floorsFilter}
            masterSketchRef={masterSketchRef}
            setSelectedUnitRender={setSelectedUnitRender}
            setPointUnit={setPointUnit}
            setUnitOri={setUnitOri}
            othersUnits={othersUnits}
            setUnitFloor={setUnitFloor}
            floorUnit={floorUnit}
          //  handleUnitChange={handleUnitChange}
        ></FullScreen>
    )} 
    </div>
    
    
  );
};

export default SelectedFloorplanSlide;
