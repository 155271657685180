/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Slider from '../Templates/_Parts/Slide';
import GalleryMasonry from '../Templates/_Parts/GalleryMasonry';
import Iframe from 'react-iframe';
import Loader from '../Templates/_Parts/Loader';
import FloorPlans from '../Templates/FloorPlans';
import LocalAmenities from '../Templates/LocalAmenities';
import BrokerAssets from '../Templates/BrokerAssets';
import HalfGallery from '../Templates/_Parts/HalfGallery';
import SiteplanLowrise from '../Templates/SiteplanLowrise';
import Tour360 from '../Templates/360Tour';
import SlideShow from '../Templates/_Parts/SlideShow';

const PageContent = ({ page, colors, projectType, sections, mapStyle }) => {
  const [loading, setLoading] = useState(true);
  const [mapCategories, setMapCategories] = useState([]);
  const [floorplanPage, setFloorplanPage] = useState(false);
 
  useEffect(() => {
    let findFloorPage = sections.find((section) => section.subsection.find((sub) => sub.template === "floorplans"))
    setFloorplanPage(findFloorPage);
    const adjustIframeHeight = () => {

      const iframe = document.querySelector('iframe');
      if (iframe) {
        iframe.style.height = `${window.innerHeight}px`;
      }
    };

    if (page?.subsection?.template === 'iframe') {
      adjustIframeHeight();
      window.addEventListener('resize', adjustIframeHeight);
    }

    return () => {
      window.removeEventListener('resize', adjustIframeHeight);
    };

  }, [page, colors]);

  useEffect(() => {

    document.title = `${page.title}`;

    if(page?.subsection[0]?.template === 'localAmenities') {
      setMapCategories(page?.subsection[0]?.categoria);
    }
  }, [page]);

  useEffect(() => {

    if (page?.subsection[0]?.template === 'iframe' && page.subsection[0].url_iframe) {
      setLoading(true);  // Reset loader state when iframe URL changes
    }
  }, [page?.subsection[0]?.url_iframe, page?.subsection[0]?.template]);

  if (!page) return <p>Loading...</p>;

  return (
    
    <>
 
      {page?.subsection[0]?.template === 'floatSquare' && page?.subsection?.[0].floatSquare_item && (
        <Slider pattern={page?.index} content={page?.subsection?.[0]} colors={colors} section={sections}/>
      )}

      {page?.subsection[0]?.template === 'masonry' && page?.subsection?.[0].images && (
        <GalleryMasonry images={page.subsection[0].images} />
      )}

      {page?.subsection[0]?.template === 'iframe' && page?.subsection?.[0].url_iframe && (
        <div className="iframe-container h-screen relative">
          {loading && <Loader />}
          <Iframe
            url={page.subsection[0].url_iframe.replace(/&amp;/g, '&')}
            width="100%"
            height="100%"
            onLoad={() => setLoading(false)}
            display="block"
            position="absolute"
            aria-label="Content iframe"
          />
        </div>
      )}

      {
        page?.subsection[0].template === 'floorplans' && projectType === 'HIGHRISE' && <FloorPlans />
      }

      {
        page?.subsection[0]?.template === 'floorplans' && projectType === 'LOWRISE' &&  <SiteplanLowrise styles={colors} />
      }

      {page?.subsection[0].template === 'localAmenities' && (
        <LocalAmenities mapCategories={mapCategories} colors={colors} project={page.subsection[0].project} mapStyle={mapStyle}/>
      )}

      {page?.subsection[0]?.template === 'assets' && (
        <BrokerAssets section={page?.subsection[0]} colors={colors} floorplanPage={floorplanPage} />
      )}

      {page?.subsection[0] && page?.subsection[0]?.template === 'halfGallery' && (
        <HalfGallery section={page?.subsection[0]} sectionIndex={page?.index} subsectionIndex="0" colors={colors}  floorplanPage={floorplanPage}/>
      )}

      {page?.subsection[0] && page?.subsection[0]?.template === '360tour' && (
       <Tour360 section={page?.subsection[0]} sectionIndex={page?.index} colors={colors}  floorplanPage={floorplanPage}/>
      )}  

      {page?.subsection[0] && page?.subsection[0]?.template === "slideshow" && (
       <SlideShow section={page?.subsection[0]} sectionIndex={page?.index}   floorplanPage={floorplanPage}/>
      )}  

    </>
  );
};

export default PageContent;
